import {
  Badge,
  Box,
  CloseButton,
  Collapse,
  Grid,
  Group,
  NumberInput,
  Paper,
  Text,
  Tooltip
} from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import {
  LabelMultiSelect,
  LabelSelectOption
} from '@/components/labels/LabelMultiSelect/LabelMultiSelect'
import { Trigger } from '@/types/businessLogic'
import {
  EventSettingType,
  EventSettings
} from '../../EventSettings/EventSettings'
import { LogicLineValuesType } from '../../types'
import { LineTitle } from './LineTitle'

type LogicLineCountProps = {
  type: LogicLineValuesType
  name: string
  color: string
  triggers: Trigger[]
  triggerError?: string
  notification: boolean
  videoUpload: boolean
  labelOptions: LabelSelectOption[]
  onLineRemove: () => void
  onTriggerChange: (triggers: Trigger[]) => void
  onSettingChange: (settingType: EventSettingType, value: boolean) => void
}

export const LogicLineCount = ({
  type,
  name,
  color,
  triggers,
  triggerError,
  notification,
  videoUpload,
  labelOptions,
  onLineRemove,
  onTriggerChange,
  onSettingChange
}: LogicLineCountProps) => {
  const selectedLabelsIds = triggers
    .map((trigger) => trigger.label_id)
    .filter((labelId) =>
      labelOptions.some((label) => label.labelId === labelId)
    )

  const handleLabelChange = (labelIds: string[]) => {
    const newTriggers = labelIds.map((labelId) => {
      const trigger = triggers.find((trigger) => trigger.label_id === labelId)
      return trigger || { label_id: labelId, threshold: 1 }
    })

    onTriggerChange(newTriggers)
  }

  const handleThresholdChange = (triggerIndex: number, value: number | '') => {
    const newTriggers = [...triggers]
    newTriggers[triggerIndex].threshold = value === '' ? 0 : value
    onTriggerChange(newTriggers)
  }

  return (
    <Paper bg="gray.1" p="xs">
      <Group justify="space-between" mb="md">
        <LineTitle type={type} name={name} color={color} />

        <Tooltip label={<FormattedMessage id="remove" />}>
          <CloseButton onClick={onLineRemove} />
        </Tooltip>
      </Group>

      <LabelMultiSelect
        label={<FormattedMessage id="logic.objectCount.labelName" />}
        placeholder={<FormattedMessage id="logic.labelPlaceholder" />}
        values={selectedLabelsIds}
        options={labelOptions}
        error={triggerError}
        onChange={handleLabelChange}
      />

      <Collapse in={triggers.length > 0}>
        <Box mt="lg">
          <Text size="xs" fw="bold" mb="md">
            <FormattedMessage id="logic.objectCount.whenCounted" />
          </Text>

          {triggers.map((trigger, i) => {
            const label = labelOptions.find(
              (label) => label.labelId === trigger.label_id
            )

            return (
              label && (
                <Box key={trigger.label_id}>
                  <Grid>
                    <Grid.Col span="content">
                      <NumberInput
                        size="xs"
                        min={0}
                        maw={120}
                        value={trigger.threshold}
                        onChange={(value) =>
                          handleThresholdChange(i, value as number | '')
                        }
                      />
                    </Grid.Col>

                    <Grid.Col span="auto">
                      <Badge size="md" color={label.color}>
                        {label.name}
                      </Badge>
                    </Grid.Col>
                  </Grid>

                  {i < triggers.length - 1 && (
                    <Text maw={120} ta="center" size="xs" fw="bold" my={6}>
                      <FormattedMessage id="logic.objectCount.or" />
                    </Text>
                  )}
                </Box>
              )
            )
          })}

          <EventSettings
            notification={notification}
            videoUpload={videoUpload}
            showNotification
            showVideoUpload
            onChange={onSettingChange}
          />
        </Box>
      </Collapse>
    </Paper>
  )
}
