import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'
import { getApiError } from '@/api/helpers/apiError'
import {
  useGetTaskStatus,
  useUpdateCvatTask
} from '@/queries/annotationQueries'
import { usePollTaskStatus } from '@/queries/generalQueries'
import { showToast } from '@/theme/notifications'
import { LibraryMLModelType } from '@/types/model'

type UseFinishCvatSessionProps = {
  taskId: number | null
  modelType: LibraryMLModelType
  datasetVersionId: string
  onError: () => void
}

export const useFinishCvatSession = ({
  taskId,
  modelType,
  datasetVersionId,
  onError
}: UseFinishCvatSessionProps) => {
  const intl = useIntl()
  const processId = useRef(uuidv4())
  const [taskIsUpdated, setTaskIsUpdated] = useState(false)
  const [updateCeleryTaskId, setUpdateCeleryTaskId] = useState<string | null>(
    null
  )

  const { mutateAsync: updateCvatTask } = useUpdateCvatTask()

  const { data: taskStatusData } = useGetTaskStatus({
    taskId: taskId as number,
    processId: processId.current,
    enabled: taskId !== null && taskIsUpdated
  })

  const { data: celeryTaskStatusData } = usePollTaskStatus(
    updateCeleryTaskId as string,
    updateCeleryTaskId !== null
  )

  useEffect(() => {
    if (taskStatusData?.status === 'Finished') {
      const updateTask = async () => {
        try {
          const { update_task_id } = await updateCvatTask({
            task_id: taskId as number,
            model_type: modelType,
            dataset_version_id: datasetVersionId
          })

          setUpdateCeleryTaskId(update_task_id)
        } catch (err) {
          const { errorMessage } = getApiError(err)

          const message =
            errorMessage || intl.formatMessage({ id: 'annotation.save.error' })

          showToast(message, 'error')
          onError()
        }
      }

      void updateTask()
    }

    if (taskStatusData?.status === 'Failed') {
      const errorMessage =
        taskStatusData.message ||
        intl.formatMessage({ id: 'annotation.save.error' })

      showToast(errorMessage, 'error')
      onError()
    }
  }, [
    taskStatusData,
    taskId,
    modelType,
    datasetVersionId,
    updateCvatTask,
    intl,
    onError
  ])

  useEffect(() => {
    if (celeryTaskStatusData?.task_status === 'FAILURE') {
      showToast(intl.formatMessage({ id: 'annotation.save.error' }), 'error')
      onError()
    }
  }, [celeryTaskStatusData, intl, onError])

  const finishCvatSession = () => {
    setTaskIsUpdated(true)
  }

  return {
    finishCvatSession,
    completed: celeryTaskStatusData?.task_status === 'SUCCESS'
  }
}
