import {
  Box,
  Card,
  Image,
  Stack,
  Text,
  ThemeIcon,
  Tooltip
} from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { IconTrash } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import ImagePlaceholder from '@/assets/image-placeholder.png'
import { CameraSource } from '@/types/device'
import { SelectableCard } from '../../../../ui-shared/SelectableCard/SelectableCard'
import { OnlineStatus } from '../../../OnlineStatus/OnlineStatus'
import { CameraSourceChip } from '../CameraSourceChip/CameraSourceChip'

const IMAGE_HEIGHT = 100

type RemoveIconProps = {
  onClick: (event: React.MouseEvent) => void
}

const RemoveIcon = ({ onClick }: RemoveIconProps) => {
  const { hovered, ref } = useHover<HTMLDivElement>()

  const iconColor = hovered
    ? 'var(--mantine-color-red-8)'
    : 'var(--mantine-color-gray-8)'

  return (
    <Tooltip position="bottom" label={<FormattedMessage id="streams.remove" />}>
      <ThemeIcon
        ref={ref}
        variant="subtle"
        radius="xl"
        size="sm"
        onClick={onClick}
      >
        <IconTrash color={iconColor} style={{ width: '70%', height: '70%' }} />
      </ThemeIcon>
    </Tooltip>
  )
}

type SelectableCameraCardProps = {
  name: string
  thumbnailUrl: string
  source: CameraSource
  isActive: boolean
  isSelected: boolean
  disabled: boolean
  onClick: () => void
  onRemoveClick: () => void
}

export const SelectableCameraCard = ({
  name,
  thumbnailUrl,
  source,
  isActive,
  isSelected,
  disabled,
  onClick,
  onRemoveClick
}: SelectableCameraCardProps) => {
  const handleRemoveClick = () => {
    onRemoveClick()
  }

  return (
    <SelectableCard
      isSelected={isSelected}
      disabled={disabled}
      height={175}
      onClick={onClick}
    >
      <Card.Section pos="relative" withBorder>
        <Image
          h={IMAGE_HEIGHT}
          fit="cover"
          src={thumbnailUrl}
          fallbackSrc={ImagePlaceholder}
        />

        <Box pos="absolute" top={4} right={8}>
          <CameraSourceChip source={source} />
        </Box>
      </Card.Section>

      <Card.Section pos="relative" py="md" inheritPadding>
        <Stack align="center" gap={4}>
          <Text w="100%" ta="center" size="sm" fw="bold" truncate>
            {name}
          </Text>

          <OnlineStatus status={isActive ? 'online' : 'offline'} />
        </Stack>

        <Box pos="absolute" top={2} right={2}>
          <RemoveIcon onClick={handleRemoveClick} />
        </Box>
      </Card.Section>
    </SelectableCard>
  )
}
