import { Button, Group, ThemeIcon, Title } from '@mantine/core'
import {
  IconCaretRightFilled,
  IconChecklist,
  IconStack
} from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type BaseModelListToolbarProps = {
  buttonsDisabled?: boolean
  onTestModel: () => void
  onSkipTraining: () => void
  onTrainModel: () => void
}

export const BaseModelListToolbar = ({
  buttonsDisabled,
  onTestModel,
  onSkipTraining,
  onTrainModel
}: BaseModelListToolbarProps) => {
  return (
    <Group h="100%" align="center" justify="space-between">
      <Group wrap="nowrap" gap="xs">
        <ThemeIcon variant="transparent" size="md">
          <IconStack style={{ width: '100%', height: '100%' }} />
        </ThemeIcon>

        <Title order={3}>
          <FormattedMessage id="models.stepper.step1" />
        </Title>
      </Group>

      <Group wrap="nowrap">
        <Button
          miw={160}
          disabled={buttonsDisabled}
          leftSection={<IconChecklist size={16} />}
          onClick={onTestModel}
        >
          <FormattedMessage id="models.test" />
        </Button>

        <Button miw={160} disabled={buttonsDisabled} onClick={onSkipTraining}>
          <FormattedMessage id="training.skip" />
        </Button>

        <Button
          color="green"
          miw={160}
          disabled={buttonsDisabled}
          rightSection={<IconCaretRightFilled size={16} />}
          onClick={onTrainModel}
        >
          <FormattedMessage id="continue" />
        </Button>
      </Group>
    </Group>
  )
}
