import { Label } from '@/types/label'
import { PaginatedResponse } from '../types/pagination'
import { vpApi } from './vpApi'

type LabelsResponse = PaginatedResponse<Label[]>

type GetLabelsParams = {
  pageUrl: string
}

const getLabels = async ({ pageUrl }: GetLabelsParams) => {
  const res = await vpApi.get<LabelsResponse>(pageUrl)
  return res.data
}

export const labelApi = {
  getLabels
}
