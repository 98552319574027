import { useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useCreateWorkspace } from '@/queries/workspaceQueries'
import { useSession } from '@/session/SessionContext'
import { getWhoAmIandMySubscription } from '@/session/helpers'
import { showToast } from '@/theme/notifications'
import { CreateWorkspaceParam } from '@/types/workspace'
import { CreateWorkspaceForm } from './CreateWorkspaceForm/CreateWorkspaceForm'

export const CreateWorkspaceHandler = () => {
  const intl = useIntl()

  const { mutateAsync: createWorkspace } = useCreateWorkspace()
  const { setUser } = useSession()

  const handleSubmit = async (formValues: CreateWorkspaceParam) => {
    try {
      await createWorkspace(formValues)
      showToast(
        intl.formatMessage({ id: 'workspace.create.success' }),
        'success'
      )

      // update user session
      const { user } = await getWhoAmIandMySubscription()
      setUser(user)
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'workspace.create.error' })

      showToast(message, 'error')
    }
  }

  return (
    <CreateWorkspaceForm
      handleSubmit={(formValues: CreateWorkspaceParam) =>
        void handleSubmit(formValues)
      }
    />
  )
}
