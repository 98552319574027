import { Button, Group, Text, ThemeIcon } from '@mantine/core'
import { IconLockFilled, IconPlus } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type CreateDatasetVersionBannerProps = {
  isPending: boolean
  onCreateVersion: () => void
}

export const CreateDatasetVersionBanner = ({
  isPending,
  onCreateVersion
}: CreateDatasetVersionBannerProps) => {
  return (
    <Group align="center" justify="space-between" wrap="nowrap">
      <Group align="center" wrap="nowrap">
        <ThemeIcon variant="transparent" color="dark.3" radius="xl" size="md">
          <IconLockFilled style={{ width: '80%', height: '80%' }} />
        </ThemeIcon>

        <Text size="xs">
          <FormattedMessage id="datasets.locked.description" />
        </Text>
      </Group>

      <Button
        size="xs"
        loading={isPending}
        leftSection={<IconPlus size={16} />}
        miw={160}
        onClick={onCreateVersion}
      >
        <FormattedMessage id="datasets.newVersion" />
      </Button>
    </Group>
  )
}
