import {
  Badge,
  Center,
  Group,
  HoverCard,
  Image,
  Stack,
  Text,
  ThemeIcon
} from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import VpLogoSrc from '@/assets/vp-stripes.png'
import { SelectableCard } from '@/components/ui-shared/SelectableCard/SelectableCard'
import { Label } from '@/types/label'
import { NetworkType } from '@/types/model'
import { BaseModelInfo } from '../BaseModelInfo/BaseModelInfo'

type BaseModelCardProps = {
  modelName: string
  labels: Label[]
  baseModelName?: string
  brandLogo?: string
  brandName?: string
  networkType?: NetworkType
  trainable?: boolean
  testable?: boolean
  deployable?: boolean
  isSelected: boolean
  showInfoOnHover?: boolean
  disabled?: boolean
  isComingSoon?: boolean
  showBadges?: boolean
  onClick: () => void
}

export const BaseModelCard = ({
  modelName,
  labels,
  baseModelName,
  brandLogo,
  brandName,
  networkType,
  trainable,
  testable,
  deployable,
  isSelected,
  showInfoOnHover,
  disabled,
  isComingSoon,
  showBadges = false,
  onClick
}: BaseModelCardProps) => {
  return (
    <SelectableCard
      height={180}
      isSelected={isSelected}
      disabled={disabled}
      onClick={onClick}
    >
      <Center mih={60}>
        <Image src={brandLogo || VpLogoSrc} h={32} w="auto" />
      </Center>

      <Stack h="100%" justify="space-between">
        <Text size="sm" ta="center" fw="bold" lineClamp={2}>
          {modelName}
        </Text>

        {showBadges && (
          <Group gap={2} justify="center">
            {trainable && (
              <Badge color="brand-primary" size="xs" variant="light">
                <FormattedMessage id="models.trainable" />
              </Badge>
            )}

            {testable && (
              <Badge color="brand-primary" size="xs" variant="light">
                <FormattedMessage id="models.testable" />
              </Badge>
            )}

            {deployable && (
              <Badge color="brand-primary" size="xs" variant="light">
                <FormattedMessage id="models.deployable" />
              </Badge>
            )}
          </Group>
        )}
      </Stack>

      {showInfoOnHover && (
        <HoverCard width={350} shadow="lg" position="bottom-start">
          <HoverCard.Target>
            <ThemeIcon
              pos="absolute"
              top={4}
              right={4}
              size="sm"
              variant="subtle"
              radius="xl"
              color="brand-primary"
            >
              <IconInfoCircle style={{ width: '90%', height: '90%' }} />
            </ThemeIcon>
          </HoverCard.Target>

          <HoverCard.Dropdown>
            <BaseModelInfo
              name={modelName}
              baseModelName={baseModelName}
              labels={labels}
              brandName={brandName}
              brandLogo={brandLogo}
              networkType={networkType}
            />
          </HoverCard.Dropdown>
        </HoverCard>
      )}

      {isComingSoon && (
        <Badge
          color="brand-secondary"
          size="xs"
          pos="absolute"
          top={4}
          right={4}
        >
          <FormattedMessage id="comingSoon" />
        </Badge>
      )}
    </SelectableCard>
  )
}
