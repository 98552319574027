import { ActionIcon, Box, Button, Center, Group, Image } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { IconCircleMinus, IconCirclePlus } from '@tabler/icons-react'
import { useRef } from 'react'
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import { FormattedMessage } from 'react-intl'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import ImagePlaceholder from '@/assets/image-placeholder.png'

const SPACING_UNIT = 100

type VideoItemProps = {
  path: string
}

const VideoItem = ({ path }: VideoItemProps) => (
  <video controls muted loop>
    <source src={path} type="video/mp4" />
  </video>
)

type ImageItemProps = {
  path: string
}

const ImageItem = ({ path }: ImageItemProps) => {
  const transformComponentRef = useRef(null)
  const contentRef = useRef(null)
  const { height: vHeight } = useViewportSize()

  return (
    <Center
      h={vHeight - 4 * SPACING_UNIT}
      bg="dark.2"
      px={SPACING_UNIT}
      py={SPACING_UNIT / 2}
    >
      <TransformWrapper
        ref={transformComponentRef}
        initialScale={1}
        minScale={0.5}
        maxScale={10}
        centerOnInit={true}
        limitToBounds={true}
        disablePadding={true}
        wheel={{ step: 1 }}
      >
        {({ zoomIn, zoomOut }) => (
          <>
            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%'
              }}
            >
              <Image
                ref={contentRef}
                src={path}
                fit="contain"
                mah={vHeight - 5 * SPACING_UNIT}
              />
            </TransformComponent>

            <Box pos="absolute" top={12} right={12}>
              <Group justify="center" gap="xs">
                <ActionIcon
                  variant="subtle"
                  color="white"
                  size="lg"
                  radius="xl"
                >
                  <IconCirclePlus
                    style={{ width: '80%', height: '80%' }}
                    onClick={() => zoomIn()}
                  />
                </ActionIcon>

                <ActionIcon
                  variant="subtle"
                  color="white"
                  size="lg"
                  radius="xl"
                >
                  <IconCircleMinus
                    style={{ width: '80%', height: '80%' }}
                    onClick={() => zoomOut()}
                  />
                </ActionIcon>
              </Group>
            </Box>
          </>
        )}
      </TransformWrapper>
    </Center>
  )
}

const isVideo = (path: string) => path.includes('.mp4')

type TestModelViewPredictionsStepProps = {
  media_paths: string[]
  onClose: () => void
  onBack: () => void
}

export const TestModelPredictionsStep = ({
  media_paths,
  onClose,
  onBack
}: TestModelViewPredictionsStepProps) => {
  const getVideoThumbnail = (filename: string) =>
    media_paths.find((item) =>
      item.includes(`_inference/thumbnails/${filename}`)
    )

  const galleryImages = media_paths
    .map((media_path) => {
      const baseObject = { original: media_path }

      if (media_path.includes('_inference/thumbnails')) {
        return null
      }

      if (isVideo(media_path)) {
        const item_filename = media_path
          .split('.mp4')[0]
          .split('_inference/')[1]

        return {
          ...baseObject,
          thumbnail: getVideoThumbnail(item_filename)
        }
      }

      return baseObject
    })
    .filter(Boolean)

  return (
    <Box>
      <ImageGallery
        items={galleryImages as ReactImageGalleryItem[]}
        infinite={false}
        showPlayButton={false}
        showFullscreenButton={false}
        renderItem={(item) => {
          return isVideo(item.original) ? (
            <VideoItem path={item.original} />
          ) : (
            <ImageItem path={item.original} />
          )
        }}
        lazyLoad
        onErrorImageURL={ImagePlaceholder}
      />
      <Group mt="lg" justify="end">
        <Button miw={160} variant="outline" onClick={onBack}>
          <FormattedMessage id="back" />
        </Button>

        <Button miw={160} onClick={onClose}>
          <FormattedMessage id="done" />
        </Button>
      </Group>
    </Box>
  )
}
