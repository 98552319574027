import {
  Button,
  Center,
  Group,
  Loader,
  SimpleGrid,
  Text,
  Title
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  IconAlertTriangle,
  IconChevronLeft,
  IconChevronRight,
  IconRefresh
} from '@tabler/icons-react'
import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { StepSection } from '@/components/deployments/deployment-editor/StepSection'
import { AddStreamHandler } from '@/components/devices/AddStreamHandler/AddStreamHandler'
import { AddStreamModal } from '@/components/devices/AddStreamModal/AddStreamModal'
import { ImportStreamHandler } from '@/components/devices/ImportStreamHandler/ImportStreamHandler'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { useGetMultipleDeviceDetails } from '@/queries/deviceQueries'
import { CameraStreamWithDeviceId } from '@/types/device'
import { AddButtonCard } from '../../../ui-shared/AddButtonCard/AddButtonCard'
import { getCameraStreamsFromDevice } from '../helpers/getCameraStreamsFromDevice'
import { RemoveStreamConfirmation } from './RemoveStreamConfirmation/RemoveStreamConfirmation'
import { SelectableCameraCard } from './SelectableCameraCard/SelectableCameraCard'

type CameraStreamSelectionProps = {
  deviceIds: string[]
  selectedCameraStreams: CameraStreamWithDeviceId[]
  onSelectionChange: (selectedCameraStreams: CameraStreamWithDeviceId[]) => void
  onGoBack: () => void
  onContinue: () => void
}

export const CameraStreamSelection = ({
  deviceIds,
  selectedCameraStreams,
  onSelectionChange,
  onGoBack,
  onContinue
}: CameraStreamSelectionProps) => {
  const [
    isAddStreamModalOpened,
    { open: openAddStreamModal, close: closeAddStreamModal }
  ] = useDisclosure()

  const [
    isRemoveStreamModalOpened,
    { open: openRemoveStreamModal, close: closeRemoveStreamModal }
  ] = useDisclosure()

  const [streamForRemoval, setStreamForRemoval] =
    useState<CameraStreamWithDeviceId | null>(null)

  const { data, isError, isFetching, refetch } =
    useGetMultipleDeviceDetails(deviceIds)
  const devices = data || []

  const cameraStreams = devices.map(getCameraStreamsFromDevice).flat()

  const isOnlineStreamSelected = selectedCameraStreams.some(
    (selectedStream) => selectedStream.connected
  )

  const isOfflineStreamSelected = selectedCameraStreams.some(
    (selectedStream) => !selectedStream.connected
  )

  const isSelected = (cameraStreamId: string) => {
    return selectedCameraStreams.some(
      (stream) => stream.camera_id === cameraStreamId
    )
  }

  const removeStreamSelection = (cameraStreamId: string) => {
    const newSelectedStreams = selectedCameraStreams.filter(
      (selectedStream) => selectedStream.camera_id !== cameraStreamId
    )

    onSelectionChange(newSelectedStreams)
  }

  const toggleCameraStreamSelection = (stream: CameraStreamWithDeviceId) => {
    const cameraStreamId = stream.camera_id

    if (isSelected(cameraStreamId)) {
      removeStreamSelection(cameraStreamId)
    } else {
      onSelectionChange([...selectedCameraStreams, stream])
    }
  }

  const handleOnReload = () => {
    void refetch()
  }

  const handleStreamCreated = useCallback(() => {
    void refetch()
    closeAddStreamModal()
  }, [refetch, closeAddStreamModal])

  const handleRemoveCameraStream = (stream: CameraStreamWithDeviceId) => {
    setStreamForRemoval(stream)
    openRemoveStreamModal()
  }

  const handleStreamRemoved = () => {
    closeRemoveStreamModal()
    removeStreamSelection(streamForRemoval?.camera_id || '')
    setStreamForRemoval(null)
    void refetch()
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={handleOnReload}>
        <FormattedMessage id="devices.listApiError" />
      </ErrorWithReload>
    )
  }

  return (
    <>
      <Group align="center" gap="lg" mb="md">
        <Title order={3}>
          <FormattedMessage id="deployments.step4" />
        </Title>

        <Button
          size="xs"
          variant="light"
          rightSection={<IconRefresh size={16} />}
          onClick={handleOnReload}
        >
          <FormattedMessage id="refresh" />
        </Button>
      </Group>

      <StepSection
        actions={
          <Group>
            <Button
              size="md"
              variant="outline"
              miw={200}
              leftSection={<IconChevronLeft size={16} stroke={3} />}
              onClick={onGoBack}
            >
              <FormattedMessage id="deployments.previousStep" />
            </Button>

            <Button
              size="md"
              miw={200}
              disabled={!isOnlineStreamSelected || isOfflineStreamSelected}
              rightSection={<IconChevronRight size={16} stroke={3} />}
              onClick={onContinue}
            >
              <FormattedMessage id="deployments.nextStep" />
            </Button>
          </Group>
        }
      >
        {isOfflineStreamSelected && (
          <Group gap="xs" wrap="nowrap" mb="sm">
            <IconAlertTriangle size={16} color="red" />

            <Text size="xs">
              <FormattedMessage id="streams.offlineStreamSelected" />
            </Text>
          </Group>
        )}

        <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 6 }} spacing="xl">
          <AddButtonCard
            height={175}
            disabled={isFetching}
            label={<FormattedMessage id="streams.addCameraStream" />}
            onClick={openAddStreamModal}
          />

          {cameraStreams.map((stream) => (
            <SelectableCameraCard
              key={stream.camera_id}
              name={stream.camera_name}
              thumbnailUrl={stream.thumbnail}
              source={stream.source}
              isActive={stream.connected}
              isSelected={isSelected(stream.camera_id)}
              disabled={!stream.connected && !isSelected(stream.camera_id)}
              onClick={() => toggleCameraStreamSelection(stream)}
              onRemoveClick={() => handleRemoveCameraStream(stream)}
            />
          ))}
        </SimpleGrid>

        {isFetching && (
          <Center h={120}>
            <Loader size={32} />
          </Center>
        )}
      </StepSection>

      <AddStreamModal
        opened={isAddStreamModalOpened}
        addStreamTab={
          <AddStreamHandler
            devices={devices}
            onCancel={closeAddStreamModal}
            onStreamCreated={handleStreamCreated}
          />
        }
        importStreamTab={
          <ImportStreamHandler
            devices={devices}
            onImportCompleted={() => void refetch()}
            onClose={closeAddStreamModal}
          />
        }
        onClose={closeAddStreamModal}
      />

      {streamForRemoval && (
        <RemoveStreamConfirmation
          opened={isRemoveStreamModalOpened}
          cameraStreamId={streamForRemoval.camera_id}
          cameraStreamName={streamForRemoval.camera_name}
          deviceId={streamForRemoval.device_id}
          deviceName={streamForRemoval.device_name}
          onClose={closeRemoveStreamModal}
          onRemovalSuccess={handleStreamRemoved}
        />
      )}
    </>
  )
}
