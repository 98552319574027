import { Annotation } from '@/types/annotation'
import { LibraryMLModelType } from '@/types/model'
import { vpApi } from './vpApi'

const getAnnotationDetails = async (annotationId: string) => {
  const res = await vpApi.get<Annotation>(`/v1/annotations/${annotationId}/`)
  return res.data
}

const deleteAnnotation = (annotationId: string) => {
  return vpApi.delete(`/v1/annotations/${annotationId}/`)
}

const cvatLogin = () => {
  return vpApi.post('/v1/cvat/login/')
}

type CreateCvatTaskData = {
  file_metadata_id: string
  cvat_fps: number
  task_name: string
  dataset_version_id: string
}

type CreateCvatTaskResponse = {
  message: string
  async_upload_video_task_id: string
  cvat_task_id: number
}

const createCvatTask = async (data: CreateCvatTaskData) => {
  const res = await vpApi.post<CreateCvatTaskResponse>('/v1/cvat/tasks/', data)
  return res.data
}

type UpdateCvatTaskData = {
  task_id: number
  model_type: LibraryMLModelType
  dataset_version_id: string
}

type UpdateCvatTaskResponse = {
  message: string
  update_task_id: string
}

const updateCvatTask = async (data: UpdateCvatTaskData) => {
  const res = await vpApi.patch<UpdateCvatTaskResponse>(
    `/v1/cvat/task/${data.task_id}/`,
    data
  )
  return res.data
}

type GetCvatUrlResponse = {
  url: string
  token: string
}

const getCvatUrl = async (taskId: number) => {
  const res = await vpApi.get<GetCvatUrlResponse>(
    `/v1/cvat/task/${taskId}/url/`
  )
  return res.data
}

type TaskVideoUploadStatusParams = {
  taskId: number
  datasetVersionId: string
}

type TaskVideoUploadStatusResponse = {
  status: 'Finished' | 'Pending' | 'Failed'
  message: string
}

const getTaskVideoUploadStatus = async ({
  taskId,
  datasetVersionId
}: TaskVideoUploadStatusParams) => {
  const res = await vpApi.get<TaskVideoUploadStatusResponse>(
    `/v1/cvat/task/${taskId}/video-upload-status/${datasetVersionId}/`
  )
  return res.data
}

type TaskStatusResponse = {
  status: 'Finished' | 'Pending' | 'Failed'
  message: string
}

const getTaskStatus = async (taskId: number) => {
  const res = await vpApi.get<TaskStatusResponse>(
    `/v1/cvat/task/${taskId}/status/`
  )
  return res.data
}

export const annotationApi = {
  getAnnotationDetails,
  deleteAnnotation,
  cvatLogin,
  createCvatTask,
  updateCvatTask,
  getCvatUrl,
  getTaskVideoUploadStatus,
  getTaskStatus
}
