import { ReactNode, createContext, useContext } from 'react'
import { Device } from '@/types/device'

type ContextProps = {
  devices: Device[]
  onImportCompleted: () => void
  onClose: () => void
}

const initialValues = {
  devices: [],
  onImportCompleted: () => {},
  onClose: () => {}
}

const ImportContext = createContext<ContextProps>(initialValues)

type ProviderProps = {
  devices: Device[]
  children: ReactNode
  onImportCompleted: () => void
  onClose: () => void
}

export const ImportProvider = ({
  devices,
  children,
  onImportCompleted,
  onClose
}: ProviderProps) => {
  return (
    <ImportContext.Provider
      value={{
        devices,
        onImportCompleted,
        onClose
      }}
    >
      {children}
    </ImportContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useImportContext = () => {
  return useContext(ImportContext)
}
