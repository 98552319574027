import { DatasetVersion } from './dataset'

export interface MLModel {
  id: string
  dataset_version: DatasetVersion | null
  name: string
  ml_model_files: string[]
  status: MLModelStatus | null // library models have no status
  status_display: string | null
  is_master_model: boolean
  is_active: boolean
  network_type: NetworkType | null
  brand_name: BrandName | null
  brand_name_display: string | null
  brand_logo: string | null
  model_code: string
  model_type: LibraryMLModelType | null
  model_type_display: string | null
  can_be_trained: boolean
  can_be_deployed: boolean
  can_be_tested: boolean
  deployments: string[]
  base_model: MLModel | null
  root_model: MLModel | null
  training: string | null
  created_by: string
  created_at: string
  updated_at: string
}

export enum MLModelStatus {
  Draft = 1,
  PreTrained = 2,
  Trained = 3,
  Evaluated = 4,
  Deployed = 5
}

export enum NetworkType {
  TAO = 1,
  CNN = 2
}

enum BrandName {
  NVIDIA_TAO = 1,
  visionplatform = 2
}

export enum LibraryMLModelType {
  ObjectDetection = 1,
  Segmentation = 2
}
