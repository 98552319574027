import { useInfiniteQuery } from '@tanstack/react-query'
import { labelApi } from '@/api/labelApi'
import { objectToQueryString } from '@/utils/url'

export const labelQueryKeys = {
  all: ['labels'] as const,
  search: (params: UseGetLabelsParams['urlParams']) =>
    [...labelQueryKeys.all, 'search', params] as const
}

type UseGetLabelsParams = {
  urlParams?: {
    search?: string
    limit?: number
    ordering?: string
  }
  enabled?: boolean
}

export const useGetLabels = ({ urlParams, enabled }: UseGetLabelsParams) => {
  const params = {
    search: '',
    limit: 20,
    ordering: 'name',
    ...(urlParams || {})
  }

  return useInfiniteQuery({
    queryKey: labelQueryKeys.search(params),
    queryFn: ({ pageParam }) => labelApi.getLabels({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam: `/v1/applications/labels/?${objectToQueryString(params)}`,
    enabled
  })
}
