import { Badge, Group, Text, ThemeIcon, rem } from '@mantine/core'
import { IconCalendar, IconCheck, IconUser } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { getFormattedDateWithTime } from '@/utils/date'

export const AnnotationStatus = () => {
  return (
    <Badge
      variant="light"
      color="green"
      leftSection={
        <ThemeIcon color="green" radius="xl" size={14}>
          <IconCheck style={{ width: '80%', height: '80%' }} stroke={3} />
        </ThemeIcon>
      }
      styles={{
        root: {
          height: rem(24),
          padding: `0 ${rem(8)}`
        }
      }}
    >
      <FormattedMessage id="annotation.labeled" />
    </Badge>
  )
}

type AnnotationAuthorProps = {
  labelerEmail: string
}

export const AnnotationAuthor = ({ labelerEmail }: AnnotationAuthorProps) => {
  return (
    <Group wrap="nowrap" gap={4}>
      <IconUser size={16} />
      <Text size="sm">{labelerEmail}</Text>
    </Group>
  )
}

type AnnotationDateProps = {
  date: string
}

export const AnnotationDate = ({ date }: AnnotationDateProps) => {
  return (
    <Group wrap="nowrap" gap={4}>
      <IconCalendar size={16} />
      <Text size="sm">{getFormattedDateWithTime(date)}</Text>
    </Group>
  )
}

type DisplayFpsProps = {
  labelFps: number
  originalFps: number
}

export const DisplayFps = ({ labelFps, originalFps }: DisplayFpsProps) => {
  return (
    <Group wrap="nowrap" gap="xl">
      <Text size="xs" component="div">
        <FormattedMessage
          id="annotation.labelFpsValue"
          values={{
            value: (
              <Badge size="xs" ml={4}>
                {labelFps}
              </Badge>
            )
          }}
        />
      </Text>

      <Text size="xs" component="div">
        <FormattedMessage
          id="annotation.originalFpsValue"
          values={{
            value: (
              <Badge size="xs" ml={4}>
                {originalFps}
              </Badge>
            )
          }}
        />
      </Text>
    </Group>
  )
}
