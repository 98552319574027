import { Box, Button, Group, LoadingOverlay, Modal, Text } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useDeleteDatasetVersion } from '@/queries/datasetQueries'
import { showToast } from '@/theme/notifications'

type DeleteDatasetVersionModalProps = {
  opened: boolean
  datasetVersionId: string
  onClose: () => void
  onVersionDeleted: () => void
}

export const DeleteDatasetVersionModal = ({
  opened,
  datasetVersionId,
  onClose,
  onVersionDeleted
}: DeleteDatasetVersionModalProps) => {
  const intl = useIntl()
  const { mutateAsync: deleteVersion, isPending } = useDeleteDatasetVersion()

  const handleVersionDelete = async () => {
    try {
      await deleteVersion(datasetVersionId)

      onVersionDeleted()

      showToast(
        intl.formatMessage({ id: 'datasets.version.delete.success' }),
        'success'
      )
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage ||
        intl.formatMessage({ id: 'datasets.version.delete.error' })

      showToast(message, 'error')
    }
  }

  return (
    <Modal
      title={<FormattedMessage id="datasets.version.delete" />}
      size="lg"
      opened={opened}
      onClose={onClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <Text size="sm" mb="xl">
          <FormattedMessage id="datasets.version.delete.confirmation" />
        </Text>

        <Group justify="end">
          <Button miw={120} variant="default" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button color="red" onClick={() => void handleVersionDelete()}>
            <FormattedMessage id="datasets.version.delete" />
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}
