import { Box, Center, Divider, Loader, Stack } from '@mantine/core'
import { useState } from 'react'
import {
  useGetDatasetDetails,
  useGetDatasetVersions
} from '@/queries/datasetQueries'
import { DatasetInfoSection } from './DatasetInfoSection/DatasetInfoSection'
import { DatasetPreviewDrawer } from './DatasetPreviewDrawer'
import { DatasetPreviewLayout } from './DatasetPreviewLayout'
import { DatasetVersionDetails } from './DatasetVersionDetails'
import { DatasetVersionLinks } from './DatasetVersionLinks/DatasetVersionLinks'

type DatasetPreviewProps = {
  datasetId: string
  opened: boolean
  onClose: () => void
}

export const DatasetPreview = ({
  datasetId,
  opened,
  onClose
}: DatasetPreviewProps) => {
  const [selectedVersionId, setSelectedVersionId] = useState('')
  const [isGalleryOpened, setIsGalleryOpened] = useState(false)

  const { data: dataset, isLoading } = useGetDatasetDetails(
    datasetId,
    datasetId !== ''
  )

  const { data: versionsData } = useGetDatasetVersions(
    datasetId,
    datasetId !== ''
  )
  const versions = versionsData?.pages?.flatMap((page) => page.results) || []

  const activeVersionId = selectedVersionId || versions?.[0]?.id

  const handleOnClose = () => {
    setSelectedVersionId('')
    onClose()
  }

  return (
    <>
      <DatasetPreviewDrawer
        opened={opened}
        closeOnEscape={!isGalleryOpened}
        onClose={handleOnClose}
      >
        <DatasetPreviewLayout
          sidebar={
            <>
              {isLoading && (
                <Center mih={100}>
                  <Loader size="sm" />
                </Center>
              )}

              {dataset && (
                <Stack gap="md">
                  <Box p="md">
                    <DatasetInfoSection
                      datasetName={dataset.name}
                      description={dataset.description}
                      datasetType={dataset.dataset_type}
                      createdAt={dataset.created_at}
                      labelingClasses={dataset.labels}
                    />
                  </Box>

                  <Divider />

                  <Box>
                    <DatasetVersionLinks
                      selectedVersionId={activeVersionId || ''}
                      versions={versions}
                      onVersionSelect={setSelectedVersionId}
                    />
                  </Box>
                </Stack>
              )}
            </>
          }
        >
          <Box py="md" pr="md">
            {activeVersionId && (
              <DatasetVersionDetails
                versionId={activeVersionId}
                onOverlayChange={setIsGalleryOpened}
              />
            )}
          </Box>
        </DatasetPreviewLayout>
      </DatasetPreviewDrawer>
    </>
  )
}
