import { Button, Card, Group, Text } from '@mantine/core'
import {
  IconBell,
  IconBox,
  IconPencil,
  IconRefresh,
  IconTrash
} from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { BORDERED_CARD_STYLES } from '@/theme/customStyles'

type DeploymentToolbarProps = {
  applicationName: string
  isFetching: boolean
  isEditDisabled: boolean
  isRemoveDisabled: boolean
  onRefreshClick: () => void
  onViewEventsClick: () => void
  onEditClick: () => void
  onRemoveClick: () => void
}

export const DeploymentToolbar = ({
  applicationName,
  isFetching,
  isEditDisabled,
  isRemoveDisabled,
  onRefreshClick,
  onViewEventsClick,
  onEditClick,
  onRemoveClick
}: DeploymentToolbarProps) => (
  <Card styles={BORDERED_CARD_STYLES} withBorder>
    <Group justify="space-between" align="center">
      <Group align="center" wrap="nowrap" gap="xs">
        <IconBox size={20} />

        <Text size="sm" fw="bold">
          {applicationName}
        </Text>
      </Group>

      <Group>
        <Button
          radius="xl"
          variant="subtle"
          leftSection={<IconRefresh size={14} />}
          onClick={onRefreshClick}
        >
          {isFetching ? (
            <FormattedMessage id="refreshing" />
          ) : (
            <FormattedMessage id="refresh" />
          )}
        </Button>

        <Button
          miw={160}
          rightSection={<IconBell size={16} />}
          onClick={onViewEventsClick}
        >
          <FormattedMessage id="events.view" />
        </Button>

        <Button
          miw={160}
          disabled={isEditDisabled}
          rightSection={<IconPencil size={16} />}
          onClick={onEditClick}
        >
          <FormattedMessage id="deployments.edit" />
        </Button>

        <Button
          miw={160}
          color="red"
          disabled={isRemoveDisabled}
          rightSection={<IconTrash size={16} />}
          onClick={onRemoveClick}
        >
          <FormattedMessage id="remove" />
        </Button>
      </Group>
    </Group>
  </Card>
)
