import { Tabs } from '@mantine/core'
import { IconCircleCheckFilled, IconLock } from '@tabler/icons-react'
import { ReactNode } from 'react'

type LeftSectionIconProps = Pick<
  ModelListTabProps,
  'isCompleted' | 'isDisabled'
>

const LeftSectionIcon = ({ isCompleted, isDisabled }: LeftSectionIconProps) => {
  if (isCompleted) {
    return (
      <IconCircleCheckFilled size={16} color="var(--mantine-color-green-6)" />
    )
  }

  if (isDisabled) {
    return <IconLock size={16} />
  }

  return null
}

type ModelListTabProps = {
  value: string
  label: string | ReactNode
  isCompleted?: boolean
  isDisabled?: boolean
}

export const ModelListTab = ({
  value,
  label,
  isCompleted,
  isDisabled
}: ModelListTabProps) => {
  return (
    <Tabs.Tab
      value={value}
      leftSection={
        <LeftSectionIcon isCompleted={isCompleted} isDisabled={isDisabled} />
      }
      disabled={isDisabled}
    >
      {label}
    </Tabs.Tab>
  )
}
