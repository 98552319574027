import { Button, Checkbox, Group, Text } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type FileSelectionBarProps = {
  allFilesSelected: boolean
  onSelectionChange: (selected: boolean) => void
  fileCount: number
  onDeleteFiles: () => void
}

export const FileSelectionBar = ({
  allFilesSelected,
  onSelectionChange,
  fileCount,
  onDeleteFiles
}: FileSelectionBarProps) => {
  return (
    <Group align="center" gap={80}>
      <Group gap="xl">
        <Checkbox
          label={<FormattedMessage id="selectAll" />}
          checked={allFilesSelected}
          onChange={() => onSelectionChange(!allFilesSelected)}
        />

        <Text size="xs" fw="bold">
          <FormattedMessage
            id="datasets.filesSelected"
            values={{
              count: fileCount
            }}
          />
        </Text>
      </Group>

      <Button
        size="xs"
        radius="xl"
        variant="subtle"
        leftSection={<IconTrash size={14} />}
        onClick={onDeleteFiles}
      >
        <FormattedMessage id="datasets.deleteSelected" />
      </Button>
    </Group>
  )
}
