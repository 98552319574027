import {
  Button,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
  Title
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { FormattedMessage, useIntl } from 'react-intl'
import { CreateWorkspaceParam, WorkspaceType } from '@/types/workspace'

const alphanumericRegex = /^[a-zA-Z0-9\s]*$/

type CreateWorkspaceFormParam = {
  handleSubmit: (formValues: CreateWorkspaceParam) => void
}

export const CreateWorkspaceForm = ({
  handleSubmit
}: CreateWorkspaceFormParam) => {
  const intl = useIntl()
  const form = useForm({
    initialValues: {
      workspace_type: WorkspaceType.Personal,
      name: ''
    },

    validate: {
      name: (value) => {
        if (value.length === 0) {
          return intl.formatMessage({ id: 'workspace.createForm.nameRequired' })
        }

        if (!alphanumericRegex.test(value)) {
          return intl.formatMessage({ id: 'workspace.createForm.invalidName' })
        }
      }
    }
  })

  return (
    <>
      <Title order={2} mb="xl">
        <FormattedMessage id="workspace.createForm" />
      </Title>
      <form
        noValidate
        onSubmit={form.onSubmit((values) => void handleSubmit(values))}
      >
        <Stack gap="sm">
          <TextInput
            label={intl.formatMessage({ id: 'workspace.createForm.name' })}
            {...form.getInputProps('name')}
          />

          <Text size="sm">
            <FormattedMessage id="workspace.createForm.type" />
          </Text>

          <SegmentedControl
            {...form.getInputProps('workspace_type')}
            data={[
              {
                label: intl.formatMessage({
                  id: 'workspace.type.organization'
                }),
                value: WorkspaceType.Organization
              },
              {
                label: intl.formatMessage({ id: 'workspace.type.personal' }),
                value: WorkspaceType.Personal
              }
            ]}
            fullWidth
          />

          <Button type="submit" mt="sm" fullWidth>
            <FormattedMessage id="workspace.createForm" />
          </Button>
        </Stack>
      </form>
    </>
  )
}
