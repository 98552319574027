import { useEffect } from 'react'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import { MLModelStatus } from '@/types/model'
import { useModelStepNavigation } from './useModelStepNavigation'

export const ModelDetailsPage = () => {
  const { application } = useApplicationContext()
  const { model } = useModelContext()

  const { goToBaseModelScreen, goToLabelDataScreen, goToModelTrainingScreen } =
    useModelStepNavigation({
      appId: application?.id || '',
      modelId: model?.id || ''
    })

  useEffect(() => {
    if (!model) {
      return
    }

    if (model.status === MLModelStatus.Trained || model.training !== null) {
      goToModelTrainingScreen(true)
      return
    }

    if (model.dataset_version !== null) {
      goToLabelDataScreen(true)
      return
    }

    goToBaseModelScreen(true)
  }, [model, goToBaseModelScreen, goToLabelDataScreen, goToModelTrainingScreen])

  return null
}
