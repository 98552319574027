import { Group, Progress, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import {
  MIN_ANNOTATED_IMAGES_COUNT,
  MIN_ANNOTATED_VIDEOS_COUNT,
  MIN_ANNOTATION_POINTS
} from '@/config/annotation'
import { getAnnotationPoints } from '../helpers/getAnnotationPoints'

type AnnotationProgressBarProps = {
  annotatedImagesCount: number
  annotatedVideosCount: number
}

export const AnnotationProgressBar = ({
  annotatedImagesCount,
  annotatedVideosCount
}: AnnotationProgressBarProps) => {
  const annotationProgressValue =
    (getAnnotationPoints(annotatedImagesCount, annotatedVideosCount) /
      MIN_ANNOTATION_POINTS) *
    100

  return (
    <Stack gap={4}>
      <Group wrap="nowrap" align="center" gap="xs">
        <Text size="sm" fw="bold">
          <FormattedMessage
            id="annotation.filesLabeled"
            values={{
              count: annotatedImagesCount + annotatedVideosCount
            }}
          />
        </Text>

        <Tooltip
          label={
            <FormattedMessage
              id="annotation.minRequirements"
              values={{
                minImageCount: MIN_ANNOTATED_IMAGES_COUNT,
                minVideoCount: MIN_ANNOTATED_VIDEOS_COUNT
              }}
            />
          }
        >
          <ThemeIcon variant="light" radius="xl" size="xs">
            <IconInfoCircle style={{ width: '80%', height: '80%' }} />
          </ThemeIcon>
        </Tooltip>
      </Group>

      <Progress size="md" value={annotationProgressValue} color="green" />
    </Stack>
  )
}
