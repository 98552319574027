import {
  Box,
  Button,
  Group,
  Modal,
  SimpleGrid,
  Title,
  Tooltip
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { StepSection } from '@/components/deployments/deployment-editor/StepSection'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { InfiniteScroll } from '@/components/ui-shared/InfiniteScroll/InfiniteScroll'
import { ModelContext } from '@/providers/ModelContext'
import { useGetLibraryLogics } from '@/queries/deploymentQueries'
import { LibraryLogic, LibraryLogicType } from '@/types/businessLogic'
import { CameraStreamWithDeviceId } from '@/types/device'
import { MLModel } from '@/types/model'
import { BusinessLogicName } from './BusinessLogicName'
import { LogicHandler } from './LogicHandler/LogicHandler'
import { ObjectMeasureHandler } from './ObjectMeasureHandler/ObjectMeasureHandler'
import { SelectableBusinessLogicCard } from './SelectableBusinessLogicCard/SelectableBusinessLogicCard'
import { SavedStreamLogic } from './types'

type LogicSelectionProps = {
  model: MLModel | null
  cameraStreams: CameraStreamWithDeviceId[]
  savedStreamLogics: SavedStreamLogic[]
  liveInferenceCameraStreamIds: string[]
  onLiveInferenceChange: (cameraStreamIds: string[]) => void
  onGoBack: () => void
  onContinue: () => void
  onStreamLogicsSave: (logics: SavedStreamLogic[]) => void
}

export const LogicSelection = ({
  model,
  cameraStreams,
  savedStreamLogics,
  liveInferenceCameraStreamIds,
  onLiveInferenceChange,
  onGoBack,
  onContinue,
  onStreamLogicsSave
}: LogicSelectionProps) => {
  const [selectedLibraryLogic, setSelectedLibraryLogic] =
    useState<LibraryLogic | null>(null)

  const [isLogicModalOpened, { open: openLogicModal, close: closeLogicModal }] =
    useDisclosure(false)

  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } =
    useGetLibraryLogics()

  const libraryLogics = data?.pages.flatMap((page) => page.results) ?? []

  const handleLoadMore = () => {
    void fetchNextPage()
  }

  const handleOnReload = () => {
    void refetch()
  }

  const handleLoginModalOpen = (libraryLogic: LibraryLogic) => {
    setSelectedLibraryLogic(libraryLogic)
    openLogicModal()
  }

  const handleLogicSave = (updatedLogics: SavedStreamLogic[]) => {
    const savedLogics = savedStreamLogics.filter(
      (savedLogic) => savedLogic.logic_type !== selectedLibraryLogic?.logic_type
    )
    onStreamLogicsSave([...savedLogics, ...updatedLogics])

    const updatedLiveInferenceCameraStreamIds = updatedLogics
      .filter((logic) => logic.live_inference)
      .map((logic) => logic.camera_id)

    onLiveInferenceChange(updatedLiveInferenceCameraStreamIds)

    closeLogicModal()
  }

  const hasSomeLogicSelected = savedStreamLogics.some(
    (logic) => logic.lines.length > 0
  )

  const isLogicTypeSelected = (type: LibraryLogicType) =>
    savedStreamLogics.some(
      (logic) => logic.logic_type === type && logic.lines.length > 0
    )

  if (isError) {
    return (
      <ErrorWithReload onReload={handleOnReload}>
        <FormattedMessage id="logic.listApiError" />
      </ErrorWithReload>
    )
  }

  return (
    <>
      <Title order={3} mb="md">
        <FormattedMessage id="deployments.step5" />
      </Title>

      <StepSection
        actions={
          <Group>
            <Button
              size="md"
              variant="outline"
              miw={200}
              leftSection={<IconChevronLeft size={16} stroke={3} />}
              onClick={onGoBack}
            >
              <FormattedMessage id="deployments.previousStep" />
            </Button>

            <Button
              size="md"
              miw={200}
              rightSection={<IconChevronRight size={16} stroke={3} />}
              onClick={onContinue}
            >
              <FormattedMessage id="deployments.nextStep" />
            </Button>
          </Group>
        }
      >
        <InfiniteScroll
          hasMore={hasNextPage}
          isFetching={isFetching}
          onLoadMore={handleLoadMore}
        >
          <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 6 }} spacing="xl">
            {libraryLogics.map((logic) => (
              <Tooltip
                key={logic.id}
                disabled={
                  !hasSomeLogicSelected || isLogicTypeSelected(logic.logic_type)
                }
                label={<FormattedMessage id="logic.singleLogicPerStream" />}
                w={300}
                multiline
              >
                <Box>
                  <SelectableBusinessLogicCard
                    businessLogicType={logic.logic_type}
                    isSelected={isLogicTypeSelected(logic.logic_type)}
                    disabled={
                      hasSomeLogicSelected &&
                      !isLogicTypeSelected(logic.logic_type)
                    } // temporary limitation requested by ML team, will be removed in the future
                    onClick={() => handleLoginModalOpen(logic)}
                  />
                </Box>
              </Tooltip>
            ))}
          </SimpleGrid>
        </InfiniteScroll>
      </StepSection>

      <Modal
        opened={isLogicModalOpened}
        closeOnEscape={false}
        size={1420}
        title={
          selectedLibraryLogic?.logic_type && (
            <BusinessLogicName
              businessLogicType={selectedLibraryLogic.logic_type}
            />
          )
        }
        onClose={closeLogicModal}
      >
        {selectedLibraryLogic &&
          (selectedLibraryLogic.logic_type ===
            LibraryLogicType.ObjectDetection ||
            selectedLibraryLogic.logic_type ===
              LibraryLogicType.ObjectCount) && (
            <ModelContext.Provider value={{ model, isFetching: false }}>
              <LogicHandler
                libraryLogic={selectedLibraryLogic}
                cameraStreams={cameraStreams}
                savedStreamLogics={savedStreamLogics}
                liveInferenceCameraStreamIds={liveInferenceCameraStreamIds}
                onCancel={closeLogicModal}
                onSave={handleLogicSave}
              />
            </ModelContext.Provider>
          )}

        {selectedLibraryLogic &&
          selectedLibraryLogic.logic_type ===
            LibraryLogicType.ObjectMeasurement3d && (
            <ModelContext.Provider value={{ model, isFetching: false }}>
              <ObjectMeasureHandler
                libraryLogic={selectedLibraryLogic}
                cameraStreams={cameraStreams}
                savedStreamLogics={savedStreamLogics}
                liveInferenceCameraStreamIds={liveInferenceCameraStreamIds}
                onCancel={closeLogicModal}
                onSave={handleLogicSave}
              />
            </ModelContext.Provider>
          )}
      </Modal>
    </>
  )
}
