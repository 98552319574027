import { Box, Button, Group, LoadingOverlay, Modal, Text } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useDeleteAnnotation } from '@/queries/annotationQueries'
import { showToast } from '@/theme/notifications'

type DeleteAnnotationModalProps = {
  opened: boolean
  annotationId: string
  onClose: () => void
  onDelete: () => void
}

export const DeleteAnnotationModal = ({
  opened,
  annotationId,
  onClose,
  onDelete
}: DeleteAnnotationModalProps) => {
  const intl = useIntl()
  const { isPending, mutateAsync: deleteAnnotation } = useDeleteAnnotation()

  const handleTaskRemove = async () => {
    try {
      await deleteAnnotation(annotationId)

      onDelete()

      showToast(
        intl.formatMessage({ id: 'annotation.remove.success' }),
        'success'
      )
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'annotation.remove.error' })

      showToast(message, 'error')
    }
  }

  return (
    <Modal
      size="lg"
      opened={opened}
      title={<FormattedMessage id="annotation.remove" />}
      onClose={onClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <Text size="sm" mb="xl">
          <FormattedMessage id="annotation.remove.confirmation" />
        </Text>

        <Group justify="end">
          <Button miw={120} variant="default" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button color="red" onClick={() => void handleTaskRemove()}>
            <FormattedMessage id="annotation.remove" />
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}
