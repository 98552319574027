import { Alert, Button, Card, Group, Text, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import {
  IconChevronLeft,
  IconChevronRight,
  IconInfoCircle
} from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import MilestoneLogo from '@/assets/integrations/milestone.png'
import VpLogo from '@/assets/vp-stripes.png'
import { StepSection } from '@/components/deployments/deployment-editor/StepSection'
import { IntegrationListItem } from '@/components/integrations/IntegrationListItem/IntegrationListItem'
import { IntergrationInputHandler } from '@/components/integrations/IntergrationInputHandler/IntergrationInputHandler'
import { EventDestination } from '@/types/deployment'
import { isValidUrl } from '@/utils/validation/url'

export type SavedEventEndpoint = {
  service: EventDestination
  endpoint?: string
}

type ConfigValues = {
  name: string
  description: string
  logo: string
}

const integrationConfig: Record<EventDestination, ConfigValues> = {
  [EventDestination.Visionplatform]: {
    name: 'integrations.website.title',
    description: 'integrations.website.description',
    logo: VpLogo
  },
  [EventDestination.Milestone]: {
    name: 'integrations.milestone.title',
    description: 'integrations.milestone.description',
    logo: MilestoneLogo
  }
}

type DisplayIntegration = {
  name: string
  description: string
  logo: string
  type: EventDestination
  endpointUrl: string
  endpointUrlRequired: boolean
  isSelected: boolean
}

type IntegrationSelectionProps = {
  selectedDeviceIds: string[]
  savedEventEndpoints: SavedEventEndpoint[]
  onGoBack: () => void
  onContinue: (endpoints: SavedEventEndpoint[]) => void
}

export const IntegrationSelection = ({
  selectedDeviceIds,
  savedEventEndpoints,
  onGoBack,
  onContinue
}: IntegrationSelectionProps) => {
  const intl = useIntl()

  const form = useForm<{ integrations: DisplayIntegration[] }>({
    initialValues: {
      integrations: (
        Object.entries(integrationConfig) as [EventDestination, ConfigValues][]
      ).map(([type, config]) => {
        const savedEndpoint = savedEventEndpoints.find(
          (endpoint) => endpoint.service === type
        )

        return {
          name: config.name,
          description: config.description,
          logo: config.logo,
          type: type,
          endpointUrl: savedEndpoint?.endpoint || '',
          endpointUrlRequired: type !== EventDestination.Visionplatform,
          isSelected: savedEndpoint !== undefined
        }
      })
    },

    validateInputOnChange: true,

    validate: {
      integrations: {
        endpointUrl: (value, formValues, path) => {
          const index = Number(path.split('.')[1])
          const integration = formValues.integrations[index]

          if (
            integration.isSelected &&
            integration.endpointUrlRequired &&
            !isValidUrl(value)
          ) {
            return intl.formatMessage({
              id: 'validation.url.invalid'
            })
          }

          return null
        }
      }
    }
  })

  const handleSelectionChange = (index: number, value: boolean) => {
    form.setFieldValue(`integrations.${index}.isSelected`, value)
    form.clearErrors()
  }

  const handleContinueClick = () => {
    const selectedIntegrations = form.values.integrations.filter(
      (integration) => integration.isSelected
    )

    const savedEventEndpoints = selectedIntegrations.map((integration) => {
      return {
        service: integration.type,
        endpoint: integration.endpointUrl
      }
    })

    onContinue(savedEventEndpoints)
  }

  return (
    <>
      <Title order={3} mb="md">
        <FormattedMessage id="deployments.step6" />
      </Title>

      <StepSection
        actions={
          <Group>
            <Button
              size="md"
              variant="outline"
              miw={200}
              leftSection={<IconChevronLeft size={16} stroke={3} />}
              onClick={onGoBack}
            >
              <FormattedMessage id="deployments.previousStep" />
            </Button>

            <Button
              miw={200}
              size="md"
              rightSection={<IconChevronRight size={16} stroke={3} />}
              disabled={!form.isValid()}
              onClick={handleContinueClick}
            >
              <FormattedMessage id="deployments.nextStep" />
            </Button>
          </Group>
        }
      >
        <Card p="lg">
          <Card.Section py="md" inheritPadding>
            <Text size="sm">
              <FormattedMessage id="integrations.notificationPreference" />
            </Text>
          </Card.Section>

          {form.values.integrations.map((integration, index) => (
            <Card.Section
              key={integration.type}
              py="xl"
              inheritPadding
              withBorder
            >
              <IntegrationListItem
                isSelected={integration.isSelected}
                title={<FormattedMessage id={integration.name} />}
                description={<FormattedMessage id={integration.description} />}
                logoSrc={integration.logo}
                showDetails={integration.isSelected}
                onChange={(value) => handleSelectionChange(index, value)}
              >
                {integration.type === EventDestination.Milestone && (
                  <>
                    <Alert
                      variant="light"
                      color="blue"
                      icon={<IconInfoCircle />}
                    >
                      <Text size="xs">
                        <FormattedMessage id="integrations.milestone.info" />
                      </Text>
                    </Alert>

                    <IntergrationInputHandler
                      inputProps={{
                        ...form.getInputProps(
                          `integrations.${index}.endpointUrl`
                        ),
                        label: intl.formatMessage({
                          id: 'integrations.endpointUrl'
                        }),
                        placeholder: 'https://your-milestone.com/notifications'
                      }}
                      deviceId={selectedDeviceIds[0]} // TODO we have a task to validate URL for multiple devices
                      isTestButtonDisabled={
                        !form.isValid(`integrations.${index}.endpointUrl`)
                      }
                    />
                  </>
                )}
              </IntegrationListItem>
            </Card.Section>
          ))}
        </Card>
      </StepSection>
    </>
  )
}
