import { SimpleGrid } from '@mantine/core'
import { InfiniteScroll } from '@/components/ui-shared/InfiniteScroll/InfiniteScroll'
import { useGetMLModels } from '@/queries/modelQueries'
import { BaseModelCard } from '../../BaseModelSelection/BaseModelCard/BaseModelCard'
import { ModelsErrorLoader } from '../ModelsErrorLoader/ModelsErrorLoader'

type MasterModelListProps = {
  selectedModelId: string
  onSelectionChange: (modelId: string) => void
}

export const MasterModelList = ({
  selectedModelId,
  onSelectionChange
}: MasterModelListProps) => {
  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } =
    useGetMLModels({
      is_library: true,
      is_master_model: true
    })

  const masterLibraryMlModels = (
    data?.pages?.flatMap((page) => page.results) || []
  ).sort((a, b) => Number(b.is_active) - Number(a.is_active)) // active models first

  if (isError) {
    return <ModelsErrorLoader onReload={() => void refetch()} />
  }

  return (
    <InfiniteScroll
      hasMore={hasNextPage}
      isFetching={isFetching}
      onLoadMore={() => void fetchNextPage()}
    >
      <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 6 }} spacing="xl">
        {masterLibraryMlModels.map((model) => (
          <BaseModelCard
            key={model.id}
            modelName={model.name}
            labels={model.dataset_version?.labels || []}
            brandLogo={model.brand_logo || undefined}
            brandName={model.brand_name_display || undefined}
            networkType={model.network_type || undefined}
            trainable={model.can_be_trained}
            testable={model.can_be_tested}
            deployable={model.can_be_deployed}
            disabled={!model.is_active}
            isComingSoon={!model.is_active}
            isSelected={selectedModelId === model.id}
            showBadges={
              model.can_be_deployed ||
              model.can_be_trained ||
              model.can_be_tested
            }
            onClick={() => onSelectionChange(model.id)}
          />
        ))}
      </SimpleGrid>
    </InfiniteScroll>
  )
}
