import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { annotationApi } from '@/api/annotationApi'

export const annotationQueryKeys = {
  all: ['annotations'] as const,
  details: (annotationId: string) =>
    [...annotationQueryKeys.all, 'details', annotationId] as const,
  cvatUrl: (taskId: number) =>
    [...annotationQueryKeys.all, 'cvatUrl', taskId] as const,
  taskVideoUploadStatus: (taskId: number, datasetVersionId: string) =>
    [
      ...annotationQueryKeys.all,
      'taskVideoUploadStatus',
      taskId,
      datasetVersionId
    ] as const,
  taskStatus: (taskId: number, processId: string) =>
    [...annotationQueryKeys.all, 'taskStatus', taskId, processId] as const
}

export const useGetAnnotation = (annotationId: string, enabled?: boolean) => {
  return useQuery({
    queryKey: annotationQueryKeys.details(annotationId),
    queryFn: () => annotationApi.getAnnotationDetails(annotationId),
    enabled,
    retry: false
  })
}

export const useCvatLogin = () => {
  return useMutation({
    mutationFn: annotationApi.cvatLogin
  })
}

export const useCreateCvatTask = () => {
  return useMutation({
    mutationFn: annotationApi.createCvatTask
  })
}

export const useUpdateCvatTask = () => {
  return useMutation({
    mutationFn: annotationApi.updateCvatTask
  })
}

type UseGetCvatUrlParams = {
  taskId: number
  enabled: boolean
}

export const useGetCvatUrl = ({ taskId, enabled }: UseGetCvatUrlParams) => {
  return useQuery({
    queryKey: annotationQueryKeys.cvatUrl(taskId),
    queryFn: () => annotationApi.getCvatUrl(taskId),
    enabled
  })
}

type UseGetTaskVideoUploadStatusParams = {
  taskId: number
  datasetVersionId: string
  enabled?: boolean
}

export const useGetTaskVideoUploadStatus = ({
  taskId,
  datasetVersionId,
  enabled
}: UseGetTaskVideoUploadStatusParams) => {
  return useQuery({
    queryKey: annotationQueryKeys.taskVideoUploadStatus(
      taskId,
      datasetVersionId
    ),
    queryFn: () =>
      annotationApi.getTaskVideoUploadStatus({ taskId, datasetVersionId }),
    refetchInterval: (query) => {
      const { data, error } = query.state

      const isFinished =
        data?.status === 'Finished' || data?.status === 'Failed' || error

      return isFinished ? false : 3000
    },
    enabled
  })
}

type UseGetTaskStatusParams = {
  taskId: number
  processId: string
  enabled?: boolean
}

export const useGetTaskStatus = ({
  taskId,
  processId,
  enabled
}: UseGetTaskStatusParams) => {
  return useQuery({
    queryKey: annotationQueryKeys.taskStatus(taskId, processId),
    queryFn: () => annotationApi.getTaskStatus(taskId),
    refetchInterval: (query) => {
      const { data, error } = query.state

      const isFinished =
        data?.status === 'Finished' || data?.status === 'Failed' || error

      return isFinished ? false : 3000
    },
    enabled
  })
}

export const useDeleteAnnotation = () => {
  return useMutation({
    mutationFn: annotationApi.deleteAnnotation
  })
}

export const useResetAnnotation = (annotationId: string) => {
  const queryClient = useQueryClient()

  const resetAnnotation = useCallback(() => {
    void queryClient.resetQueries({
      queryKey: annotationQueryKeys.details(annotationId)
    })
  }, [queryClient, annotationId])

  return { resetAnnotation }
}
