import { Deployment } from '@/types/deployment'
import { CameraStream, Device } from '@/types/device'

export const buildDeploymentLines = (
  deployments: Deployment[],
  devices: Device[]
) => {
  return deployments.map((deployment) => {
    const device = devices.find(
      (d) => d.id === deployment.settings_payload.device_id
    )

    const streamAttrs = device
      ? device.attributes.filter((attr) => attr.key === 'cameras')
      : []

    const streams = (streamAttrs[0]?.value || []) as CameraStream[]

    const currentStream = streams.find(
      (stream) => stream.camera_id === deployment.settings_payload.stream_id
    )

    const logicNames = deployment.settings_payload.business_logics.map(
      (logic) => logic.name as string
    )

    return {
      deploymentId: deployment.id,
      streamId: currentStream?.camera_id || '',
      deviceName: device?.label || '',
      isDeviceOnline: device?.active || false,
      streamName: currentStream?.camera_name || '',
      isStreamOnline: currentStream?.connected || false,
      modelName: deployment.ml_model.name,
      logicNames,
      deploymentStatus: deployment.status,
      deploymentError: deployment.error_body
    }
  })
}
