import { Box, Button, Group } from '@mantine/core'
import { IconCheck, IconLock, IconX } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { MediaFileName } from '@/components/datasets/MediaGallery/MediaFileName'
import { FileMetadata } from '@/types/dataset'

type LabelModeToolbarProps = {
  fileName: string
  fileType: FileMetadata['media_type']
  isSaveDisabled?: boolean
  onCancel: () => void
  onSave: () => void
}

export const LabelModeToolbar = ({
  fileName,
  fileType,
  isSaveDisabled,
  onCancel,
  onSave
}: LabelModeToolbarProps) => {
  return (
    <Group justify="space-between" align="center">
      <Box>
        <MediaFileName fileName={fileName} fileType={fileType} />
      </Box>

      <Group>
        <Button
          miw={200}
          color="green.8"
          leftSection={
            isSaveDisabled ? (
              <IconLock size={16} stroke={3} />
            ) : (
              <IconCheck size={16} stroke={3} />
            )
          }
          disabled={isSaveDisabled}
          onClick={onSave}
        >
          <FormattedMessage id="saveChanges" />
        </Button>

        <Button
          miw={200}
          variant="outline"
          leftSection={<IconX size={16} stroke={3} />}
          onClick={onCancel}
        >
          <FormattedMessage id="exitWithoutSaving" />
        </Button>
      </Group>
    </Group>
  )
}
