import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import {
  useCreateCvatTask,
  useCvatLogin,
  useGetCvatUrl,
  useGetTaskVideoUploadStatus
} from '@/queries/annotationQueries'
import { showToast } from '@/theme/notifications'

type UseStartCvatSessionParams = {
  fileMetadataId: string
  datasetVersionId: string
  taskName: string
  taskId?: number
  onError: () => void
}

export const useStartCvatSession = ({
  fileMetadataId,
  datasetVersionId,
  taskName,
  taskId,
  onError
}: UseStartCvatSessionParams) => {
  const intl = useIntl()
  const [isLogged, setIsLogged] = useState(false)
  const [cvatTaskId, setCvatTaskId] = useState<number | null>(null)

  const { mutateAsync: login } = useCvatLogin()
  const { mutateAsync: createCvatTask } = useCreateCvatTask()

  const { data: taskStatusData } = useGetTaskVideoUploadStatus({
    taskId: cvatTaskId as number,
    datasetVersionId,
    enabled: cvatTaskId !== null
  })

  const { data: cvatUrlData } = useGetCvatUrl({
    taskId: taskId ?? (cvatTaskId as number),
    enabled:
      taskStatusData?.status === 'Finished' ||
      (taskId !== undefined && isLogged)
  })

  useEffect(() => {
    if (taskStatusData?.status === 'Failed') {
      const errorMessage =
        taskStatusData.message ||
        intl.formatMessage({ id: 'annotation.loading.error' })
      showToast(errorMessage, 'error')
      onError()
    }
  }, [intl, onError, taskStatusData])

  const startCvatSession = useCallback(
    async (fps: number) => {
      try {
        await login()
        setIsLogged(true)

        if (taskId === undefined) {
          const { cvat_task_id } = await createCvatTask({
            file_metadata_id: fileMetadataId,
            dataset_version_id: datasetVersionId,
            cvat_fps: fps,
            task_name: taskName
          })

          setCvatTaskId(cvat_task_id)
        }
      } catch (err) {
        const { errorMessage } = getApiError(err)

        const message =
          errorMessage || intl.formatMessage({ id: 'annotation.loading.error' })

        showToast(message, 'error')
        onError()
      }
    },
    [
      fileMetadataId,
      login,
      createCvatTask,
      taskName,
      taskId,
      datasetVersionId,
      intl,
      onError
    ]
  )

  return {
    startCvatSession,
    cvatTaskId: taskId ?? cvatTaskId,
    iframeUrl: cvatUrlData?.url,
    token: cvatUrlData?.token
  }
}
