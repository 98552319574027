import {
  Box,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Text
} from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useDeleteDataset } from '@/queries/datasetQueries'
import { showToast } from '@/theme/notifications'

const RESTORE_DAYS = 30

type DeleteDatasetModalProps = {
  opened: boolean
  datasetId: string
  onClose: () => void
  onDelete: () => void
}

export const DeleteDatasetModal = ({
  opened,
  datasetId,
  onClose,
  onDelete
}: DeleteDatasetModalProps) => {
  const intl = useIntl()
  const { isPending, mutateAsync: deleteDataset } = useDeleteDataset()

  const handleDatasetDelete = async () => {
    try {
      await deleteDataset(datasetId)

      onDelete()

      showToast(
        intl.formatMessage({ id: 'datasets.delete.success' }),
        'success'
      )
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'datasets.delete.error' })

      showToast(message, 'error')
    }
  }

  return (
    <Modal
      size="lg"
      opened={opened}
      title={<FormattedMessage id="datasets.delete" />}
      onClose={onClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <Stack gap="xl">
          <Text size="sm">
            <FormattedMessage id="datasets.delete.confirmation" />
          </Text>

          <Text size="sm">
            <FormattedMessage
              id="datasets.delete.restore"
              values={{
                days: RESTORE_DAYS
              }}
            />
          </Text>

          <Group justify="end">
            <Button miw={120} variant="default" onClick={onClose}>
              <FormattedMessage id="cancel" />
            </Button>

            <Button
              miw={120}
              color="red"
              onClick={() => void handleDatasetDelete()}
            >
              <FormattedMessage id="datasets.delete" />
            </Button>
          </Group>
        </Stack>
      </Box>
    </Modal>
  )
}
