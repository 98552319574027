import { Button, Flex, SimpleGrid, Stack } from '@mantine/core'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { UploadDropzone } from '@/components/datasets/UploadDropzone/UploadDropzone'
import { TestModelMediaCard } from './TestModelMediaCard'

const MAXIMUM_FILES = 5

type DropzoneFileListProps = {
  files: File[]
  onFileDelete: (fileName: string) => void
}

const DropzoneFileList = ({ files, onFileDelete }: DropzoneFileListProps) => {
  return (
    <SimpleGrid cols={{ xs: 2, lg: 7 }} spacing="md">
      {files.map((file, index) => (
        <TestModelMediaCard
          key={index}
          file={file}
          onFileDelete={() => onFileDelete(file.name)}
        />
      ))}
    </SimpleGrid>
  )
}

type TestModelUploadMediaProps = {
  isInferencePending: boolean
  onStartInference: (files: File[]) => void
}

export const TestModelUploadMedia = ({
  isInferencePending,
  onStartInference
}: TestModelUploadMediaProps) => {
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])

  const handleDrop = (files: File[]) => {
    setAcceptedFiles([...files, ...acceptedFiles])
  }

  const handleFileRemove = (fileName: string) => {
    const newFiles = acceptedFiles.filter((file) => file.name !== fileName)
    setAcceptedFiles(newFiles)
  }

  return (
    <>
      <Stack>
        {acceptedFiles.length > 0 && (
          <DropzoneFileList
            files={acceptedFiles}
            onFileDelete={handleFileRemove}
          />
        )}

        <UploadDropzone
          disabled={acceptedFiles.length >= MAXIMUM_FILES || isInferencePending}
          fileLimit={MAXIMUM_FILES}
          minHeight={480}
          showInstructions
          onDrop={handleDrop}
          onReject={() => {}}
        />

        <Flex justify="end">
          <Button
            miw={160}
            disabled={acceptedFiles.length === 0}
            loading={isInferencePending}
            onClick={() => onStartInference(acceptedFiles)}
          >
            <FormattedMessage id="continue" />
          </Button>
        </Flex>
      </Stack>
    </>
  )
}
