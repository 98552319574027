import { Alert, Box, Stack, Tabs, Tooltip, rem } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'
import { Application, WorkflowType } from '@/types/app'
import { PageWithFixedToolbar } from '../layout/PageWithFixedToolbar/PageWithFixedToolbar'
import { ModelList } from './ModelList/ModelList'
import { ModelListTab } from './ModelList/ModelListTab'
import { useModelListStatus } from './ModelList/useModelListStatus'
import { ModelListToolbar } from './ModelListToolbar/ModelListToolbar'

type IsDeployDisabledParams = {
  primaryModelCount: number
  secondaryModelCount: number
  workflowType: WorkflowType
}

const isDeployDisabled = ({
  primaryModelCount,
  secondaryModelCount,
  workflowType
}: IsDeployDisabledParams) => {
  if (workflowType === WorkflowType.SingleStage) {
    return primaryModelCount === 0
  }

  if (workflowType === WorkflowType.MultiStage) {
    return primaryModelCount === 0 || secondaryModelCount === 0
  }

  return true
}

export const ModelsPage = () => {
  const navigate = useNavigate()
  const { application } = useApplicationContext()
  const { workflow_type = WorkflowType.SingleStage } =
    application as Application // TODO: remove test value after API integration

  const { primaryModelCount, secondaryModelCount, isStatusLoading } =
    useModelListStatus(application?.id || '')

  const defaultTab =
    primaryModelCount > 0 && secondaryModelCount === 0 ? 'secondary' : 'primary'

  const handleOnDeploy = () => {
    navigate(
      buildAppLink(
        application?.id || '',
        ApplicationNestedPath.createDeployment
      )
    )
  }

  return (
    <PageWithFixedToolbar
      toolbar={
        <ModelListToolbar
          isMultiStage={workflow_type === WorkflowType.MultiStage}
          isDeployDisabled={isDeployDisabled({
            primaryModelCount,
            secondaryModelCount,
            workflowType: workflow_type
          })}
          isLoading={isStatusLoading}
          onDeploy={handleOnDeploy}
        />
      }
    >
      {workflow_type === WorkflowType.SingleStage && (
        <Box pt="lg">
          <ModelList applicationId={application?.id || ''} />
        </Box>
      )}

      {workflow_type === WorkflowType.MultiStage && (
        <Tabs
          defaultValue={defaultTab}
          variant="pills"
          radius="md"
          styles={{
            tab: {
              minWidth: rem(120)
            },
            tabLabel: {
              fontSize: rem(14),
              fontWeight: 600
            }
          }}
        >
          <Tabs.List>
            <ModelListTab
              value="primary"
              label={<FormattedMessage id="models.primary" />}
              isCompleted={primaryModelCount > 0}
            />

            <ModelListTab
              value="secondary"
              label={
                <Tooltip
                  w={240}
                  disabled={primaryModelCount > 0}
                  label={<FormattedMessage id="models.secondary.unlock" />}
                  position="bottom"
                  multiline
                >
                  <div>
                    <FormattedMessage id="models.secondary" />
                  </div>
                </Tooltip>
              }
              isCompleted={secondaryModelCount > 0}
              isDisabled={primaryModelCount === 0}
            />
          </Tabs.List>

          <Tabs.Panel value="primary">
            <Stack pt="lg" gap="lg">
              {primaryModelCount === 0 && (
                <Alert color="blue">
                  <FormattedMessage id="workflows.multiStage.getStartedInfo" />
                </Alert>
              )}

              <ModelList applicationId={application?.id || ''} />
            </Stack>
          </Tabs.Panel>

          <Tabs.Panel value="secondary">
            <Box pt="lg">
              <ModelList applicationId={application?.id || ''} />
            </Box>
          </Tabs.Panel>
        </Tabs>
      )}
    </PageWithFixedToolbar>
  )
}
