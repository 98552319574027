import { Drawer } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

type TrainingDrawerProps = {
  opened: boolean
  children?: React.ReactNode
  onClose: () => void
}

export const TrainingDrawer = ({
  opened,
  children,
  onClose
}: TrainingDrawerProps) => {
  return (
    <Drawer
      opened={opened}
      position="right"
      size="xl"
      title={<FormattedMessage id="models.trainModel" />}
      onClose={onClose}
    >
      {children}
    </Drawer>
  )
}
