import { Card, Image, LoadingOverlay, Stack, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import ImagePlaceholder from '@/assets/image-placeholder.png'
import { LoadingStatus } from './status'

type LoadingMessageProps = {
  imageUrl: string
  loadingStatus: LoadingStatus
}

export const LoadingMessage = ({
  imageUrl,
  loadingStatus
}: LoadingMessageProps) => {
  return (
    <Stack align="center" justify="center" gap="xl">
      <Card w={160} pos="relative" padding={0} withBorder>
        <LoadingOverlay
          loaderProps={{
            type: 'dots',
            size: 32
          }}
          visible
        />

        <Image src={imageUrl} fallbackSrc={ImagePlaceholder} fit="cover" />
      </Card>

      {loadingStatus === LoadingStatus.prepareFile && (
        <Text size="sm">
          <FormattedMessage id="annotation.loading.message" />
        </Text>
      )}

      {loadingStatus === LoadingStatus.saveChanges && (
        <Text size="sm">
          <FormattedMessage id="annotation.save.message" />
        </Text>
      )}
    </Stack>
  )
}
