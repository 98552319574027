import { SimpleGrid, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { InfiniteScroll } from '@/components/ui-shared/InfiniteScroll/InfiniteScroll'
import { useGetAppMLModels } from '@/queries/modelQueries'
import { MLModelStatus } from '@/types/model'
import { BaseModelCard } from '../../BaseModelSelection/BaseModelCard/BaseModelCard'
import { ModelsErrorLoader } from '../ModelsErrorLoader/ModelsErrorLoader'

type UserTrainedModelListProps = {
  applicationId: string
  selectedModelId: string
  onSelectionChange: (modelId: string) => void
}

export const UserTrainedModelList = ({
  applicationId,
  selectedModelId,
  onSelectionChange
}: UserTrainedModelListProps) => {
  const {
    data: userModels,
    isFetching: isFetching,
    isError: isError,
    refetch: refetch,
    fetchNextPage: fetchNextPage,
    hasNextPage: hasNextPageUserModels
  } = useGetAppMLModels(applicationId, {
    status: MLModelStatus.Trained
  })

  const userMlModels = userModels?.pages?.flatMap((page) => page.results) || []

  if (isError) {
    return <ModelsErrorLoader onReload={() => void refetch()} />
  }

  if (userMlModels.length === 0) {
    return (
      <Text size="sm">
        <FormattedMessage id="models.noUserTrainedModels" />
      </Text>
    )
  }

  return (
    <InfiniteScroll
      hasMore={hasNextPageUserModels}
      isFetching={isFetching}
      onLoadMore={() => void fetchNextPage()}
    >
      <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 6 }} spacing="xl">
        {userMlModels.map((model) => (
          <BaseModelCard
            key={model.id}
            modelName={model.name}
            labels={model.dataset_version?.labels || []}
            baseModelName={model?.root_model?.name}
            brandLogo={model?.root_model?.brand_logo || undefined}
            brandName={model?.root_model?.brand_name_display || undefined}
            networkType={model?.root_model?.network_type || undefined}
            trainable={model?.root_model?.can_be_trained}
            testable={model?.root_model?.can_be_tested}
            deployable={model?.root_model?.can_be_deployed}
            isSelected={selectedModelId === model.id}
            showInfoOnHover
            onClick={() => onSelectionChange(model.id)}
          />
        ))}
      </SimpleGrid>
    </InfiniteScroll>
  )
}
