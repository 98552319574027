import { Box, Button, Flex, Group, Title } from '@mantine/core'
import { IconArrowRight, IconChevronLeft, IconVideo } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { StepSection } from '@/components/deployments/deployment-editor/StepSection'
import { CameraStreamWithDeviceId } from '@/types/device'
import { MLModel, MLModelStatus } from '@/types/model'
import { SelectableDeviceCard } from '../DeviceSelection/SelectableDeviceCard/SelectableDeviceCard'
import { IntegrationPreviewCard } from '../IntegrationSelection/IntegrationPreviewCard/IntegrationPreviewCard'
import { SavedEventEndpoint } from '../IntegrationSelection/IntegrationSelection'
import { SelectableBusinessLogicCard } from '../LogicSelection/SelectableBusinessLogicCard/SelectableBusinessLogicCard'
import { SavedStreamLogic } from '../LogicSelection/types'
import { SelectableModelCard } from '../ModelSelection/SelectableModelCard/SelectableModelCard'
import { PreviewCard } from './PreviewCard'

type DevicePreview = {
  id: string
  name: string
  isActive: boolean
}

const Arrow = () => (
  <Flex h={120} direction="column" align="center" justify="center">
    <IconArrowRight size={24} />
  </Flex>
)

type DeployPreviewProps = {
  primaryModel: MLModel | null
  secondaryModel: MLModel | null
  cameraStreams: CameraStreamWithDeviceId[]
  savedStreamLogics: SavedStreamLogic[]
  savedEventEndpoints: SavedEventEndpoint[]
  onDeployModel: () => void
  onGoBack: () => void
}

export const DeployPreview = ({
  primaryModel,
  secondaryModel,
  cameraStreams,
  savedStreamLogics,
  savedEventEndpoints,
  onDeployModel,
  onGoBack
}: DeployPreviewProps) => {
  // Get all unique devices from camera list
  const devices = cameraStreams.reduce((acc, cameraStream) => {
    if (!acc.find((device) => device.id === cameraStream.device_id)) {
      acc.push({
        id: cameraStream.device_id,
        name: cameraStream.device_name,
        isActive: cameraStream.is_device_active
      })
    }
    return acc
  }, [] as DevicePreview[])

  const getLogicTypes = (logics: SavedStreamLogic[], deviceId: string) => {
    const logicTypes = logics
      .filter((logic) => logic.device_id === deviceId)
      .filter((logic) => logic.lines.length > 0)
      .map((logic) => logic.logic_type)

    // Remove duplicates
    return [...new Set(logicTypes)]
  }

  return (
    <>
      <Title order={3} mb="md">
        <FormattedMessage id="deployments.step7" />
      </Title>

      <Box pos="relative">
        <StepSection
          actions={
            <Group>
              <Button
                size="md"
                variant="outline"
                miw={200}
                leftSection={<IconChevronLeft size={16} stroke={3} />}
                onClick={onGoBack}
              >
                <FormattedMessage id="deployments.previousStep" />
              </Button>

              <Button size="md" miw={200} onClick={onDeployModel}>
                <FormattedMessage
                  id="deployments.deployModel"
                  values={{
                    count: 1
                  }}
                />
              </Button>
            </Group>
          }
        >
          {devices.map((device) => {
            const logicTypes = getLogicTypes(savedStreamLogics, device.id)

            return (
              <Group key={device.id} align="center" mb="xl">
                <Box w={175}>
                  <SelectableModelCard
                    name={primaryModel?.name || ''}
                    status={primaryModel?.status as MLModelStatus}
                  />
                </Box>

                <Arrow />

                {secondaryModel && (
                  <>
                    <Box w={175}>
                      <SelectableModelCard
                        name={secondaryModel.name}
                        status={secondaryModel.status as MLModelStatus}
                      />
                    </Box>

                    <Arrow />
                  </>
                )}

                <Box w={175}>
                  <SelectableDeviceCard
                    name={device.name}
                    isActive={device.isActive}
                  />
                </Box>

                <Arrow />

                <Box w={175}>
                  <PreviewCard icon={<IconVideo size={24} />}>
                    <FormattedMessage
                      id="deployments.preview.streamCount"
                      values={{
                        count: cameraStreams.filter(
                          (stream) => stream.device_id === device.id
                        ).length
                      }}
                    />
                  </PreviewCard>
                </Box>

                <Arrow />

                <Box w={175}>
                  {/* We currently support only one logic per deployment */}
                  {logicTypes.length > 0 ? (
                    <SelectableBusinessLogicCard
                      businessLogicType={logicTypes[0]}
                    />
                  ) : (
                    <PreviewCard>
                      <FormattedMessage id="deployments.preview.noLogicSelected" />
                    </PreviewCard>
                  )}
                </Box>

                <Arrow />

                <Box w={175}>
                  <IntegrationPreviewCard
                    eventEndpoints={savedEventEndpoints}
                  />
                </Box>
              </Group>
            )
          })}
        </StepSection>
      </Box>
    </>
  )
}
