import { Combobox, Group, InputBase, Loader, useCombobox } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { DatasetVersion } from '@/types/dataset'
import { getFormattedDate } from '@/utils/date'
import { DatasetStatusChip } from '../DatasetStatusChip/DatasetStatusChip'

type DatasetVersionOption = {
  value: string
  label: string
  version?: DatasetVersion
}

type DatasetVersionSelectProps = {
  value: string
  versions: DatasetVersionOption[]
  isPending?: boolean
  onChange: (value: string) => void
}

export const DatasetVersionSelect = ({
  value,
  versions,
  isPending,
  onChange
}: DatasetVersionSelectProps) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  })

  const selectedOption = versions.find((option) => option.value === value)

  const options = versions.map((item) => (
    <Combobox.Option key={item.value} value={item.value}>
      <Group gap={6} py={2}>
        <IconCheck
          size={14}
          color="var(--mantine-color-green-6)"
          stroke={4}
          style={{
            visibility: item.value === value ? 'visible' : 'hidden'
          }}
        />

        {item.label}

        {item.version && (
          <span>
            (
            {getFormattedDate(item.version.created_at, {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            })}
            )
          </span>
        )}

        {item.version && (
          <div style={{ marginInlineStart: 'auto' }}>
            <DatasetStatusChip status={item.version.status} />
          </div>
        )}
      </Group>
    </Combobox.Option>
  ))

  return (
    <Combobox
      store={combobox}
      size="xs"
      width={320}
      position="bottom-start"
      onOptionSubmit={(val) => {
        onChange(val)
        combobox.closeDropdown()
      }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          type="button"
          rightSection={isPending ? <Loader size={16} /> : <Combobox.Chevron />}
          rightSectionPointerEvents="none"
          size="xs"
          pointer
          onClick={() => combobox.toggleDropdown()}
        >
          {!isPending && (
            <Group gap="xs">
              {selectedOption?.label}

              {selectedOption?.version && (
                <DatasetStatusChip status={selectedOption.version.status} />
              )}
            </Group>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options mah={400} style={{ overflowY: 'auto' }}>
          {options}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
