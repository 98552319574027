import { Box, Button, Grid, Group, Text, ThemeIcon, Title } from '@mantine/core'
import {
  IconCaretRightFilled,
  IconCheck,
  IconPhotoCheck
} from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type DatasetListToolbarProps = {
  isDatasetSelected: boolean
  name?: string
  isPending?: boolean
  onContinue: () => void
}

export const DatasetListToolbar = ({
  isDatasetSelected,
  name,
  isPending,
  onContinue
}: DatasetListToolbarProps) => {
  return (
    <Box py="sm">
      <Grid align="center">
        <Grid.Col span="auto">
          <Group wrap="nowrap" gap="xs">
            <ThemeIcon variant="transparent" size="md">
              <IconPhotoCheck style={{ width: '100%', height: '100%' }} />
            </ThemeIcon>

            <Title order={3}>
              <FormattedMessage id="models.stepper.step2" />
            </Title>
          </Group>
        </Grid.Col>

        <Grid.Col span="content">
          <Group align="center" gap={40}>
            {isDatasetSelected ? (
              <Group wrap="nowrap" gap="xs" align="center">
                <ThemeIcon size={14} color="green" radius="xl">
                  <IconCheck
                    style={{
                      width: '80%',
                      height: '80%'
                    }}
                  />
                </ThemeIcon>

                <Text size="sm">
                  <FormattedMessage
                    id="datasets.selectedName"
                    values={{
                      name: <b>&quot;{name}&quot;</b>
                    }}
                  />
                </Text>
              </Group>
            ) : (
              <Text size="sm">
                <FormattedMessage id="datasets.selectDataset" />
              </Text>
            )}

            <Button
              miw={160}
              color="green"
              disabled={!isDatasetSelected}
              rightSection={<IconCaretRightFilled size={16} />}
              loading={isPending}
              onClick={onContinue}
            >
              <FormattedMessage id="continue" />
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </Box>
  )
}
