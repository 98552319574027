export enum WorkspaceType {
  Organization = '1',
  Personal = '2'
}

export type CreateWorkspaceParam = {
  workspace_type: WorkspaceType
  name: string
}

export type Workspace = {
  id: string
  name: string
  workspace_type: WorkspaceType
  created_by: string
  created_at: string
}
