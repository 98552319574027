import { CreateWorkspaceParam, Workspace } from '@/types/workspace'
import { vpApi } from './vpApi'

const createWorkspace = async (payload: CreateWorkspaceParam) => {
  const res = await vpApi.post<Workspace>('/v1/workspaces/', payload)
  return res.data
}

export const workspaceApi = {
  createWorkspace
}
