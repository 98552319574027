import { Button, Group, ThemeIcon, Title } from '@mantine/core'
import { IconPencil, IconPlus } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type DeploymentEditorToolbarProps = {
  mode: 'create' | 'edit'
  onCancelEdit: () => void
}

export const DeploymentEditorToolbar = ({
  mode,
  onCancelEdit
}: DeploymentEditorToolbarProps) => {
  return (
    <Group h="100%" align="center" justify="space-between">
      <Group wrap="nowrap" gap="xs">
        <ThemeIcon variant="transparent" size="md">
          {mode === 'edit' ? (
            <IconPencil style={{ width: '90%', height: '90%' }} />
          ) : (
            <IconPlus style={{ width: '90%', height: '90%' }} />
          )}
        </ThemeIcon>

        <Title order={3}>
          <FormattedMessage
            id={mode === 'edit' ? 'deployments.edit' : 'deployments.new'}
          />
        </Title>
      </Group>

      {mode === 'edit' && (
        <Button miw={160} variant="light" color="red" onClick={onCancelEdit}>
          <FormattedMessage id="cancelEdit" />
        </Button>
      )}
    </Group>
  )
}
