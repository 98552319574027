import {
  Checkbox,
  Combobox,
  Group,
  Input,
  Pill,
  PillsInput,
  useCombobox
} from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

export type LabelSelectOption = {
  labelId: string
  name: string
  color: string
}

type LabelMultiSelectProps = {
  label: string | ReactNode
  placeholder?: string | ReactNode
  values: string[]
  options: LabelSelectOption[]
  disabled?: boolean
  error?: ReactNode
  onChange?: (labels: string[]) => void
}

export const LabelMultiSelect = ({
  label,
  placeholder,
  values,
  options,
  disabled = false,
  error,
  onChange
}: LabelMultiSelectProps) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active')
  })

  const handleValueSelect = (val: string) => {
    if (val === '$selectAll') {
      if (values.length === options.length) {
        onChange?.([])
      } else {
        onChange?.(options.map((option) => option.labelId))
      }
    } else {
      onChange?.(
        values.includes(val)
          ? values.filter((v) => v !== val)
          : [...values, val]
      )
    }
  }

  const handleValueRemove = (val: string) => {
    if (disabled) {
      return
    }

    onChange?.(values.filter((v) => v !== val))
  }

  const pills = values
    .map((val) => {
      return options.find((option) => option.labelId === val)
    })
    .sort((a, b) => {
      return (a?.name || '').localeCompare(b?.name || '')
    })
    .map((labelObj) => (
      <Pill
        key={labelObj?.labelId}
        styles={{
          root: {
            color: 'white',
            backgroundColor: labelObj?.color
          },
          label: {
            fontSize: 11,
            fontWeight: 700
          }
        }}
        withRemoveButton={!disabled}
        onRemove={() => handleValueRemove(labelObj?.labelId || '')}
      >
        {labelObj?.name.toUpperCase()}
      </Pill>
    ))

  const sortedOptions = [...options].sort((a, b) =>
    a.name.localeCompare(b.name)
  )

  const displayOptions = sortedOptions.map((item) => (
    <Combobox.Option
      key={item.labelId}
      value={item.labelId}
      active={values.includes(item.labelId)}
    >
      <Group gap="sm">
        <Checkbox
          checked={values.includes(item.labelId)}
          tabIndex={-1}
          style={{ pointerEvents: 'none' }}
          aria-hidden
          onChange={() => {}}
        />
        <span>{item.name}</span>
      </Group>
    </Combobox.Option>
  ))

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      disabled={disabled}
      onOptionSubmit={handleValueSelect}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          label={label}
          disabled={disabled}
          error={error}
          pointer
          onClick={() => combobox.toggleDropdown()}
        >
          <Pill.Group disabled={disabled}>
            {values.length > 0 ? (
              pills
            ) : (
              <Input.Placeholder>{placeholder}</Input.Placeholder>
            )}

            <Combobox.EventsTarget>
              <PillsInput.Field
                type="hidden"
                onBlur={() => combobox.closeDropdown()}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    event.preventDefault()
                    handleValueRemove(values[values.length - 1])
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options mah={260} style={{ overflowY: 'auto' }}>
          <Combobox.Option value="$selectAll">
            <Group gap="sm">
              <Checkbox
                checked={values.length === options.length}
                tabIndex={-1}
                style={{ pointerEvents: 'none' }}
                aria-hidden
                onChange={() => {}}
              />

              <strong>
                <FormattedMessage id="selectAll" />
              </strong>
            </Group>
          </Combobox.Option>

          {displayOptions}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
