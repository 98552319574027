import { Drawer, Image, Modal, Tooltip, createTheme } from '@mantine/core'

export const theme = createTheme({
  /** Put your mantine theme override here */
  colors: {
    'brand-primary': [
      '#d0d2e7',
      '#a6a9c9',
      '#7d81aa',
      '#5b6090',
      '#3f4571',
      '#2c3158',
      '#171b40', // base color
      '#131637',
      '#0f112e',
      '#0a0c24'
    ],
    'brand-secondary': [
      '#fbd4cf',
      '#f8b4a8',
      '#f49388',
      '#f07b70',
      '#eb655b',
      '#e54f46',
      '#ee6f61', // base color
      '#c8534c',
      '#a64842',
      '#833e38'
    ]
  },
  primaryColor: 'brand-primary',
  primaryShade: 6,
  components: {
    Modal: Modal.extend({
      defaultProps: {
        padding: 'lg'
      },
      styles: {
        title: {
          fontWeight: 'bold'
        }
      }
    }),
    Drawer: Drawer.extend({
      defaultProps: {
        padding: 'lg'
      },
      styles: {
        title: {
          fontWeight: 'bold'
        }
      }
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        withArrow: true,
        openDelay: 50,
        transitionProps: { duration: 200, transition: 'pop' }
      },
      styles: (theme) => ({
        tooltip: {
          fontSize: theme.fontSizes.xs
        }
      })
    }),
    Image: Image.extend({
      styles: {
        root: {
          flex: '0 1 auto' // TODO remove after https://github.com/mantinedev/mantine/pull/5641#issuecomment-2152151729 is resolved
        }
      }
    })
  }
})
