import { CloseButton, Collapse, Group, Paper, Tooltip } from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  LabelMultiSelect,
  LabelSelectOption
} from '@/components/labels/LabelMultiSelect/LabelMultiSelect'
import { Trigger } from '@/types/businessLogic'
import {
  EventSettingType,
  EventSettings
} from '../../EventSettings/EventSettings'
import { LogicLineValuesType } from '../../types'
import { LineTitle } from './LineTitle'

type LogicLineDetectionProps = {
  type: LogicLineValuesType
  name: string
  color: string
  label: ReactNode | string
  triggers: Trigger[]
  triggerError?: string
  notification: boolean
  videoUpload: boolean
  labelOptions: LabelSelectOption[]
  onLineRemove: () => void
  onTriggerChange: (triggers: Trigger[]) => void
  onSettingChange: (settingType: EventSettingType, value: boolean) => void
}

export const LogicLineDetection = ({
  type,
  name,
  color,
  label,
  triggers,
  triggerError,
  notification,
  videoUpload,
  labelOptions,
  onLineRemove,
  onTriggerChange,
  onSettingChange
}: LogicLineDetectionProps) => {
  const selectedLabels = triggers
    .map((trigger) => trigger.label_id)
    .filter((labelId) =>
      labelOptions.some((label) => label.labelId === labelId)
    )

  const handleLabelChange = (labels: string[]) => {
    onTriggerChange(
      labels.map((label) => ({
        label_id: label,
        threshold: 1
      }))
    )
  }

  return (
    <Paper bg="gray.1" p="xs">
      <Group justify="space-between" mb="md">
        <LineTitle type={type} name={name} color={color} />

        <Tooltip label={<FormattedMessage id="remove" />}>
          <CloseButton onClick={onLineRemove} />
        </Tooltip>
      </Group>

      <LabelMultiSelect
        label={label}
        placeholder={<FormattedMessage id="logic.labelPlaceholder" />}
        values={selectedLabels}
        options={labelOptions}
        error={triggerError}
        onChange={handleLabelChange}
      />

      <Collapse in={triggers.length > 0}>
        <EventSettings
          notification={notification}
          videoUpload={videoUpload}
          showNotification
          showVideoUpload
          onChange={onSettingChange}
        />
      </Collapse>
    </Paper>
  )
}
