import { Box, Card, SimpleGrid, Title } from '@mantine/core'
import { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useGetApplications } from '@/queries/applicationQueries'
import { RouterPath, replacePathParams } from '@/router/paths'
import { Application } from '@/types/app'
import { VpDemoVideo } from '../support/VpDemoVideo/VpDemoVideo'
import { AddButtonCard } from '../ui-shared/AddButtonCard/AddButtonCard'
import { ErrorWithReload } from '../ui-shared/ErrorWithReload/ErrorWithReload'
import { InfiniteScroll } from '../ui-shared/InfiniteScroll/InfiniteScroll'
import { ApplicationCard } from './ApplicationCard/ApplicationCard'
import { CreateApplicationModal } from './CreateApplicationModal'
import { DeleteApplicationModal } from './DeleteApplicationModal'
import { UpdateApplicationModal } from './UpdateApplicationModal'

export const ApplicationsPage = () => {
  const [appToDelete, setAppToDelete] = useState<Application | null>(null)
  const [appToRename, setAppToRename] = useState<Application | null>(null)
  const [isCreateModalOpened, setIsCreateModalOpened] = useState(false)

  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } =
    useGetApplications()

  const pages = data?.pages

  const showCreateModal = () => {
    setIsCreateModalOpened(true)
  }

  const closeCreateModal = () => {
    setIsCreateModalOpened(false)
  }

  const handleOnReload = () => {
    void refetch()
  }

  const handleLoadMore = () => {
    void fetchNextPage()
  }

  const handleAppDeleted = () => {
    void refetch()
    setAppToDelete(null)
  }

  const handleAppUpdated = () => {
    void refetch()
    setAppToRename(null)
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={handleOnReload}>
        <FormattedMessage id="apps.appList.apiError" />
      </ErrorWithReload>
    )
  }

  return (
    <Box pb={120}>
      <Card mb={100} mt={40} bg="brand-primary.0" py="xl">
        <VpDemoVideo />
      </Card>

      <Title order={2} mb="lg">
        <FormattedMessage id="apps.myApps" />
      </Title>

      <InfiniteScroll
        hasMore={hasNextPage}
        isFetching={isFetching}
        onLoadMore={handleLoadMore}
      >
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 7 }} spacing="xl">
          <AddButtonCard
            height={175}
            label={<FormattedMessage id="apps.createApp" />}
            onClick={showCreateModal}
          />

          {pages?.map((page, i) => (
            <Fragment key={i}>
              {page.results.map((app) => (
                <ApplicationCard
                  key={app.id}
                  appName={app.name}
                  appDetailsLink={replacePathParams(RouterPath.appDetails, {
                    appId: app.id
                  })}
                  onDelete={() => setAppToDelete(app)}
                  onRename={() => setAppToRename(app)}
                />
              ))}
            </Fragment>
          ))}
        </SimpleGrid>
      </InfiniteScroll>

      <CreateApplicationModal
        opened={isCreateModalOpened}
        onClose={closeCreateModal}
      />

      {appToRename && (
        <UpdateApplicationModal
          app={appToRename}
          opened={appToRename !== null}
          onClose={() => setAppToRename(null)}
          onAppUpdated={handleAppUpdated}
        />
      )}

      {appToDelete && (
        <DeleteApplicationModal
          app={appToDelete}
          opened={appToDelete !== null}
          onClose={() => setAppToDelete(null)}
          onAppDeleted={handleAppDeleted}
        />
      )}
    </Box>
  )
}
