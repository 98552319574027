import { Container, rem } from '@mantine/core'
import { ReactNode } from 'react'

export const PAGE_MAX_WIDTH = 1600

// eslint-disable-next-line react-refresh/only-export-components
export const PAGE_PADDING_X = {
  base: 'md',
  md: 'xl'
}

type PageContainerProps = {
  children: ReactNode
  fullWidth?: boolean
}

export const PageContainer = ({ children, fullWidth }: PageContainerProps) => {
  return (
    <Container
      py="md"
      px={PAGE_PADDING_X}
      size={fullWidth ? '100%' : rem(PAGE_MAX_WIDTH)}
    >
      {children}
    </Container>
  )
}
