import { Group, ScrollArea, Stack, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { LabelGroup } from '@/components/labels/LabelGroup/LabelGroup'
import { Label } from '@/types/label'
import { NetworkType } from '@/types/model'

type BaseModelInfoProps = {
  name: string
  labels: Label[]
  baseModelName?: string
  brandName?: string
  brandLogo?: string
  networkType?: NetworkType
}

export const BaseModelInfo = ({
  name,
  labels,
  baseModelName,
  brandName,
  networkType
}: BaseModelInfoProps) => {
  return (
    <Stack gap="sm">
      <Text size="sm" fw="bold">
        {name}
      </Text>

      {baseModelName && (
        <Group>
          <Text c="dimmed" size="xs" fw={600}>
            <FormattedMessage id="models.basedOn" />:
          </Text>

          <Text size="xs" fw="light">
            {baseModelName}
          </Text>
        </Group>
      )}

      {networkType && (
        <Group>
          <Text c="dimmed" size="xs" fw={600}>
            <FormattedMessage id="models.networkType" />:
          </Text>

          <Text size="xs">{networkType}</Text>
        </Group>
      )}

      {brandName && (
        <Group>
          <Text c="dimmed" size="xs" fw={600}>
            <FormattedMessage id="models.brand" />:
          </Text>

          <Text size="xs">{brandName}</Text>
        </Group>
      )}

      {labels.length > 0 && (
        <ScrollArea h={160}>
          <LabelGroup labels={labels} />
        </ScrollArea>
      )}
    </Stack>
  )
}
