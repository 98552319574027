import { Group, Image, Stack, Text } from '@mantine/core'

import { FormattedMessage } from 'react-intl'
import MilestoneLogo from '@/assets/integrations/milestone.png'
import VpLogo from '@/assets/vp-stripes.png'
import { SelectableCard } from '@/components/ui-shared/SelectableCard/SelectableCard'
import { APP_NAME } from '@/config/app'
import { INTEGRATION_NAMES } from '@/config/integrations'
import { EventDestination } from '@/types/deployment'
import { SavedEventEndpoint } from '../IntegrationSelection'

const config = {
  [EventDestination.Visionplatform]: {
    name: APP_NAME,
    logo: VpLogo
  },
  [EventDestination.Milestone]: {
    name: INTEGRATION_NAMES.MILESTONE,
    logo: MilestoneLogo
  }
}

type IntegrationPreviewCardProps = {
  eventEndpoints: SavedEventEndpoint[]
}

export const IntegrationPreviewCard = ({
  eventEndpoints
}: IntegrationPreviewCardProps) => {
  return (
    <SelectableCard height={175}>
      <Stack justify="center" h="100%">
        {eventEndpoints.length === 0 && (
          <Text size="xs">
            <FormattedMessage id="integrations.noEventNotifications" />
          </Text>
        )}

        {eventEndpoints.map((eventEndpoint) => (
          <Group key={eventEndpoint.service} gap="xs" wrap="nowrap">
            <Image
              src={config[eventEndpoint.service].logo}
              w={24}
              h={24}
              fit="contain"
            />

            <Text size="xs" truncate>
              {config[eventEndpoint.service].name}
            </Text>
          </Group>
        ))}
      </Stack>
    </SelectableCard>
  )
}
