export type Inference = {
  result: InferenceResult
}

type InferenceResult = {
  inference_id: string
  status: InferenceStatus
  media_files?: string[]
  error?: string
}

export enum InferenceStatus {
  InProgress = 1,
  Success = 2,
  Failed = 3
}
