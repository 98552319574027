import { useGetDatasetVersionFiles } from '@/queries/datasetQueries'
import { FileStatus } from '@/types/dataset'

export const useMediaListStatus = (versionId: string, enabled?: boolean) => {
  const { data } = useGetDatasetVersionFiles({
    versionId,
    enabled
  })

  const files = data?.pages?.flatMap((page) => page.results) || []

  const annotatedFiles = files.filter(
    (file) => file.status === FileStatus.Labeled
  )

  const annotatedImagesCount = annotatedFiles.filter(
    (file) => file.media_type === 'image'
  ).length

  const annotatedVideosCount = annotatedFiles.filter(
    (file) => file.media_type === 'video'
  ).length

  return {
    annotatedImagesCount,
    annotatedVideosCount
  }
}
