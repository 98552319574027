export type Application = {
  id: string
  name: string
  workflow_type: WorkflowType
  created_at: string
  created_by: string
  updated_at: string
}

export enum WorkflowType {
  SingleStage = 'singleStage',
  MultiStage = 'multiStage'
}
