import { Radio, Stack } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { RadioCard } from '@/components/ui-shared/RadioCard/RadioCard'
import { DatasetType } from '@/types/dataset'

type SelectDatasetTypeProps = {
  selectedType: DatasetType
  onChange: (type: DatasetType) => void
}

export const SelectDatasetType = ({
  selectedType,
  onChange
}: SelectDatasetTypeProps) => {
  return (
    <Radio.Group
      value={String(selectedType)}
      onChange={(value) => onChange(Number(value))}
    >
      <Stack gap="xs">
        <RadioCard
          isSelected={selectedType === DatasetType.Primary}
          value={String(DatasetType.Primary)}
          title={<FormattedMessage id="datasets.type.primary" />}
          description={
            <FormattedMessage id="datasets.type.primary.description" />
          }
        />

        <RadioCard
          isSelected={selectedType === DatasetType.Secondary}
          value={String(DatasetType.Secondary)}
          title={<FormattedMessage id="datasets.type.secondary" />}
          description={
            <FormattedMessage id="datasets.type.secondary.description" />
          }
        />
      </Stack>
    </Radio.Group>
  )
}
