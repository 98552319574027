import { useDisclosure } from '@mantine/hooks'
import { useState } from 'react'
import { AnnotationHandler } from '@/components/annotations/AnnotationHandler'
import { DeleteAnnotationModal } from '@/components/annotations/DeleteAnnotationModal/DeleteAnnotationModal'
import { ViewAnnotationToolbarHandler } from '@/components/annotations/ViewAnnotationToolbarHandler'
import { useModelContext } from '@/providers/ModelContext'
import {
  useGetAnnotation,
  useResetAnnotation
} from '@/queries/annotationQueries'
import { useInvalidateFileList } from '@/queries/datasetQueries'
import { FileMetadata } from '@/types/dataset'
import { MLModel } from '@/types/model'
import { MediaGallery } from '../MediaGallery/MediaGallery'
import { MediaGalleryOverlay } from '../MediaGallery/MediaGalleryOverlay'

type MediaViewHandlerProps = {
  opened: boolean
  activeFileIndex: number
  files: FileMetadata[]
  datasetVersionId: string
  isAnnotationDisabled?: boolean
  onClose: () => void
  onActiveFileChange: (index: number) => void
}

export const MediaViewHandler = ({
  opened,
  activeFileIndex,
  files,
  datasetVersionId,
  isAnnotationDisabled,
  onClose,
  onActiveFileChange
}: MediaViewHandlerProps) => {
  const [isAnnotationMode, setIsAnnotationMode] = useState(false)

  const [
    isDeleteAnnotationModalOpened,
    { close: closeDeleteAnnotationModal, open: openDeleteAnnotationModal }
  ] = useDisclosure(false)

  const { model } = useModelContext()

  const activeFile = files[activeFileIndex] as FileMetadata | undefined
  const annotationId = activeFile?.annotation_id || null

  const { invalidateFileList } = useInvalidateFileList(datasetVersionId)
  const { resetAnnotation } = useResetAnnotation(annotationId || '')

  const { data: annotation, isFetching } = useGetAnnotation(
    annotationId || '',
    opened && annotationId !== null
    // We check for annotation_id because an unlabeled file may have an unfinished annotation with a CVAT task_id and URL.
    // This lets us resume the CVAT session without re-uploading the file.
  )

  const galleryItems = files.map((file) => ({
    original: file.media_file,
    thumbnail: file.thumbnail,
    file
  }))

  const handleSaveLabeling = () => {
    onClose()
    invalidateFileList()
    setIsAnnotationMode(false)
  }

  const handleCancelLabeling = () => {
    invalidateFileList()
    setIsAnnotationMode(false)
  }

  const handleDeleteAnnotation = () => {
    invalidateFileList()
    resetAnnotation()
    closeDeleteAnnotationModal()
  }

  return isAnnotationMode ? (
    <AnnotationHandler
      annotationTaskId={annotation?.task_id}
      file={activeFile as FileMetadata}
      libraryModel={model?.root_model as MLModel}
      datasetVersionId={datasetVersionId}
      isAnnotationDisabled={isAnnotationDisabled}
      onCancelLabeling={handleCancelLabeling}
      onSaveLabeling={handleSaveLabeling}
    />
  ) : (
    <>
      <MediaGalleryOverlay
        opened={opened}
        toolbar={
          activeFile && (
            <ViewAnnotationToolbarHandler
              file={activeFile}
              annotation={annotation}
              isLoading={isFetching}
              isAnnotationDisabled={isAnnotationDisabled}
              onStartLabeling={() => setIsAnnotationMode(true)}
              onViewLabeling={() => setIsAnnotationMode(true)}
              onRemoveLabeling={openDeleteAnnotationModal}
            />
          )
        }
        onClose={onClose}
      >
        <MediaGallery
          items={galleryItems}
          startIndex={activeFileIndex}
          showThumbnails
          onSlideChange={onActiveFileChange}
        />
      </MediaGalleryOverlay>

      <DeleteAnnotationModal
        annotationId={annotation?.id || ''}
        opened={isDeleteAnnotationModalOpened}
        onClose={closeDeleteAnnotationModal}
        onDelete={handleDeleteAnnotation}
      />
    </>
  )
}
