import { Spoiler, Stack, Text, rem } from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { LabelGroup } from '@/components/labels/LabelGroup/LabelGroup'
import { DatasetType } from '@/types/dataset'
import { Label } from '@/types/label'
import { getFormattedDate } from '@/utils/date'
import { DatasetTypeChip } from '../../DatasetTypeChip/DatasetTypeChip'

type StackedLabelProps = {
  label: string | ReactNode
  children: ReactNode
}

const StackedLabel = ({ label, children }: StackedLabelProps) => {
  return (
    <Stack gap="xs" mt="xl">
      <Text fw={600} size="sm">
        {label}
      </Text>

      {children}
    </Stack>
  )
}

type DatasetInfoSectionProps = {
  datasetName: string
  description?: string
  datasetType: DatasetType
  createdAt: string
  labelingClasses: Label[]
}

export const DatasetInfoSection = ({
  datasetName,
  description,
  datasetType,
  createdAt,
  labelingClasses
}: DatasetInfoSectionProps) => {
  return (
    <>
      <Stack>
        <Text fw="bold">{datasetName}</Text>

        <DatasetTypeChip datasetType={datasetType} />

        {description && (
          <Spoiler
            maxHeight={80}
            showLabel={<FormattedMessage id="showMore" />}
            hideLabel={<FormattedMessage id="showLess" />}
            styles={{
              control: {
                fontSize: rem(13)
              },
              content: {
                fontSize: rem(13)
              }
            }}
          >
            {description}
          </Spoiler>
        )}

        <Text size="xs" c="dimmed" fw={600}>
          <FormattedMessage
            id="createdDate"
            values={{
              date: getFormattedDate(createdAt, {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              })
            }}
          />
        </Text>
      </Stack>

      <StackedLabel label={<FormattedMessage id="annotation.classes" />}>
        <LabelGroup labels={labelingClasses} />
      </StackedLabel>
    </>
  )
}
