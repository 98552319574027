import { Modal } from '@mantine/core'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import {
  useGetInferenceDetails,
  useStartInference
} from '@/queries/inferenceQueries'
import { showToast } from '@/theme/notifications'
import { InferenceStatus } from '@/types/inference'
import { TestModelPredictionsStep } from './TestModelPredictionStep'
import { TestModelUploadMedia } from './TestModelUploadMedia'

type InferenceModalProps = {
  opened: boolean
  applicationId: string
  modelId: string
  resolution?: string
  onClose: () => void
}

export const InferenceModal = ({
  opened,
  applicationId,
  modelId,
  resolution,
  onClose
}: InferenceModalProps) => {
  const intl = useIntl()
  const [step, setStep] = useState(1)
  const [inferenceId, setInferenceId] = useState<string>('')
  const [inferredMedia, setInferredMedia] = useState<string[]>([])

  const { mutateAsync: startInference, isPending: isStartPending } =
    useStartInference()

  const { data: inferenceData, isError } = useGetInferenceDetails({
    inferenceId,
    enabled: inferenceId !== ''
  })

  const inferenceDetails = inferenceData?.result

  const isPending =
    isStartPending || inferenceDetails?.status === InferenceStatus.InProgress

  useEffect(() => {
    if (inferenceDetails) {
      if (inferenceDetails.status === InferenceStatus.Success) {
        showToast(intl.formatMessage({ id: 'models.test.success' }), 'success')
        setInferredMedia(inferenceDetails.media_files || [])
        setStep(2)
        setInferenceId('')
      }

      if (inferenceDetails?.status === InferenceStatus.Failed || isError) {
        showToast(intl.formatMessage({ id: 'models.test.error' }), 'error')
        setInferenceId('')
      }
    }
  }, [inferenceDetails, intl, isError])

  const handleResetData = () => {
    setInferenceId('')
    setInferredMedia([])
    setStep(1)
  }

  const handleOnClose = () => {
    onClose()

    if (step === 2) {
      handleResetData()
    }
  }

  const handleStartInference = async (acceptedFiles: File[]) => {
    try {
      const { data } = await startInference({
        applicationId,
        modelId,
        mediaFiles: acceptedFiles,
        resolution
      })

      setInferenceId(data.results.inference_id)
      showToast(intl.formatMessage({ id: 'models.test.loading' }), 'info')
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'models.test.error' })

      showToast(message, 'error')
    }
  }

  return (
    <Modal
      size={step === 1 ? '60rem' : '100rem'}
      opened={opened}
      title={
        step === 1 ? (
          <FormattedMessage id="models.test.uploadMedia" />
        ) : (
          <FormattedMessage id="models.test.viewPredictions" />
        )
      }
      centered
      onClose={handleOnClose}
    >
      {step === 1 ? (
        <TestModelUploadMedia
          isInferencePending={isPending}
          onStartInference={(acceptedFiles) =>
            void handleStartInference(acceptedFiles)
          }
        />
      ) : (
        <TestModelPredictionsStep
          media_paths={inferredMedia}
          onClose={handleOnClose}
          onBack={handleResetData}
        />
      )}
    </Modal>
  )
}
