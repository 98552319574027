import { Inference } from '@/types/inference'
import { vpApi } from './vpApi'

type InferModelParams = {
  applicationId: string
  modelId: string
  mediaFiles: File[]
  resolution?: string
}

type InferModelResponse = {
  results: {
    inference_id: string
  }
}

const inferModel = ({
  applicationId,
  modelId,
  mediaFiles,
  resolution
}: InferModelParams) => {
  const formData = new FormData()
  mediaFiles.forEach((file) => formData.append('media_files', file))

  formData.append('model_id', modelId)

  if (resolution) {
    formData.append('resolution', resolution)
  }

  return vpApi.post<InferModelResponse>(
    `/v1/applications/${applicationId}/models/${modelId}/inference/`,
    formData
  )
}

const getInferenceDetails = async (inferenceId: string) => {
  const res = await vpApi.get<Inference>(`/v1/inference/${inferenceId}/`)
  return res.data
}

export const inferenceApi = {
  inferModel,
  getInferenceDetails
}
