import { ActionIcon, Card, Image, Tooltip } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import ImagePlaceholder from '@/assets/image-placeholder.png'
import { AnimatedCard } from '@/components/ui-shared/AnimatedCard/AnimatedCard'

const CARD_HEIGHT = 120

type testModelMediaCardProps = {
  file: File
  onFileDelete: () => void
}

export const TestModelMediaCard = ({
  file,
  onFileDelete
}: testModelMediaCardProps) => {
  const mediaUrl = URL.createObjectURL(file)
  const isVideo = file.type.startsWith('video')

  return (
    <AnimatedCard>
      <Card.Section pos="relative">
        {isVideo ? (
          <video height={CARD_HEIGHT}>
            <source src={mediaUrl} type="video/mp4" />
          </video>
        ) : (
          <Image
            src={mediaUrl}
            alt={file.name}
            fallbackSrc={ImagePlaceholder}
            h={CARD_HEIGHT}
            fit="cover"
            onLoad={() => URL.revokeObjectURL(mediaUrl)}
          />
        )}

        <Tooltip label={<FormattedMessage id="models.test.deleteMedia" />}>
          <ActionIcon
            pos="absolute"
            top={4}
            right={4}
            color="red"
            size="sm"
            radius="xl"
            onClick={onFileDelete}
          >
            <IconTrash style={{ width: '70%', height: '70%' }} />
          </ActionIcon>
        </Tooltip>
      </Card.Section>
    </AnimatedCard>
  )
}
