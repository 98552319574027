import { Box, LoadingOverlay } from '@mantine/core'
import { useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useInvalidateModelDetails } from '@/queries/modelQueries'
import { useCreateTraining } from '@/queries/trainingQueries'
import { showToast } from '@/theme/notifications'
import { MLModel } from '@/types/model'
import { DatasetSplitType, TrainingType } from '@/types/training'
import { useModelStepNavigation } from '../models/ModelDetails/useModelStepNavigation'
import {
  FormValues,
  TrainingParamsForm
} from './TraningParamsForm/TrainingParamsForm'

type TrainingHandlerProps = {
  applicationId: string
  model: MLModel
  onCancel: () => void
}

export const TrainingHandler = ({
  applicationId,
  model,
  onCancel
}: TrainingHandlerProps) => {
  const intl = useIntl()

  const { goToModelTrainingScreen } = useModelStepNavigation({
    appId: applicationId,
    modelId: model.id
  })

  const { mutateAsync: createTraining, isPending } = useCreateTraining()

  const { invalidateModelDetails } = useInvalidateModelDetails(model.id)

  const handleTrainModel = async (values: FormValues) => {
    try {
      await createTraining({
        application_id: applicationId,
        ml_model_id: model.id,
        training_set_ids: [],
        validation_set_ids: [],
        test_set_ids: [],
        training_type:
          values.trainingType === 'fine_tune'
            ? TrainingType.FineTuning
            : TrainingType.Full,
        dataset_split_type: DatasetSplitType.Automatic,
        frozen_layers: values.frozenLayers,
        patience: values.patience,
        epoch: values.epoch,
        batch_size: values.batchSize,
        resolution: values.inferenceResolution
      })

      invalidateModelDetails()
      goToModelTrainingScreen()
      showToast(intl.formatMessage({ id: 'training.started' }), 'success')
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'training.error' })

      showToast(message, 'error')
    }
  }

  return (
    <Box pos="relative">
      <LoadingOverlay visible={isPending} />

      <TrainingParamsForm
        labels={model.dataset_version?.labels || []}
        onSubmit={(values) => void handleTrainModel(values)}
        onCancel={onCancel}
      />
    </Box>
  )
}
