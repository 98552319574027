import { Center } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'

type ModelsErrorLoaderProps = {
  onReload: () => void
}

export const ModelsErrorLoader = ({ onReload }: ModelsErrorLoaderProps) => {
  return (
    <Center w="100%">
      <ErrorWithReload onReload={onReload}>
        <FormattedMessage id="models.modelList.apiError" />
      </ErrorWithReload>
    </Center>
  )
}
