import { Box, Stack, Text, UnstyledButton } from '@mantine/core'
import { IconCirclePlus } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { AnimatedCard } from '../AnimatedCard/AnimatedCard'

const CARD_HEIGHT = 160

type AddButtonCardProps = {
  label: string | ReactNode
  height?: number
  disabled?: boolean
  onClick: () => void
}

export const AddButtonCard = ({
  label,
  height,
  disabled,
  onClick
}: AddButtonCardProps) => {
  return (
    <UnstyledButton w="100%" disabled={disabled} onClick={onClick}>
      <AnimatedCard h={height || CARD_HEIGHT}>
        <Stack h="100%" gap="lg" align="center" justify="center">
          <Box pt="xl">
            <IconCirclePlus size={32} />
          </Box>

          <Text ta="center" fw="bold" size="sm">
            {label}
          </Text>
        </Stack>
      </AnimatedCard>
    </UnstyledButton>
  )
}
