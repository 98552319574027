export type TrainingConfig = {
  application_id: string
  ml_model_id: string
  training_set_ids: string[]
  validation_set_ids: string[]
  test_set_ids: string[]
  training_type: TrainingType
  dataset_split_type: DatasetSplitType
  frozen_layers: number
  patience: number
  epoch: number
  batch_size: number
  resolution: string
}

export type Training = {
  id: string
  application: string
  ml_model: string
  training_set: unknown[]
  validation_set: unknown[]
  test_set: unknown[]
  status: TrainingStatus
  status_display: string
  training_type: TrainingType
  training_type_display: string
  dataset_split_type: DatasetSplitType
  dataset_split_type_display: string
  frozen_layers: number
  patience: number
  epoch: number
  batch_size: number
  resolution: string
  created_by: string
  created_at: string
  error_body: string
  done_at: string | null
}

export enum TrainingStatus {
  Running = 1,
  Completed = 2,
  Failed = 3
}

export enum TrainingType {
  Full = 1,
  FineTuning = 2
}

export enum DatasetSplitType {
  Automatic = 1,
  Manual = 2
}
