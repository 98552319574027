import { Box, Card, Center, Grid } from '@mantine/core'
import { FormErrors } from '@mantine/form'
import { FormattedMessage } from 'react-intl'
import { useModelContext } from '@/providers/ModelContext'
import { LibraryLogicType, ParameterType, Trigger } from '@/types/businessLogic'
import { AnnotationArea, LineAddParams } from '../AnnotationArea/AnnotationArea'
import { EventSettingType } from '../EventSettings/EventSettings'
import { useCameraImage } from '../hooks/useCameraImage'
import { LogicLineValues } from '../types'
import { EmptyLogicText } from './EmptyLogicText'
import { LogicLineCount } from './LogicLine/LogicLineCount'
import { LogicLineDetection } from './LogicLine/LogicLineDetection'
import { DEFAULT_EVENT_SETTINGS, FRAME_HEIGHT, FRAME_WIDTH } from './constants'

type StreamLogicConfiguratorProps = {
  active: boolean
  deviceId: string
  cameraStreamId: string
  logicType: LibraryLogicType
  enabledLogicParameters: ParameterType[]
  lines: LogicLineValues[]
  streamErrors: FormErrors
  onLineAdd: (line: LogicLineValues) => void
  onLineChange: (lineIndex: number, line: LogicLineValues) => void
  onLineRemove: (lineIndex: number) => void
}

export const StreamLogicConfigurator = ({
  active,
  deviceId,
  cameraStreamId,
  logicType,
  enabledLogicParameters,
  lines,
  streamErrors,
  onLineAdd,
  onLineChange,
  onLineRemove
}: StreamLogicConfiguratorProps) => {
  const { isImageLoading, cameraImage, refreshCameraImage } = useCameraImage({
    deviceId,
    cameraStreamId,
    enabled: active
  })

  const { model } = useModelContext()

  const labels =
    model?.dataset_version?.labels ||
    model?.base_model?.dataset_version?.labels ||
    []

  const labelOptions = labels.map((label) => ({
    labelId: label.id,
    name: label.name,
    color: label.color
  }))

  const handleLineAdd = ({ lineType, name, color, points }: LineAddParams) => {
    onLineAdd({
      ...DEFAULT_EVENT_SETTINGS,
      type: lineType,
      name,
      color,
      points,
      triggers: []
    })
  }

  const handleTriggerChange = (
    updatedTriggers: Trigger[],
    lineIndex: number
  ) => {
    onLineChange(lineIndex, {
      ...lines[lineIndex],
      triggers: updatedTriggers
    })
  }

  const handleSettingChange = (
    settingType: EventSettingType,
    value: boolean,
    lineIndex: number
  ) => {
    onLineChange(lineIndex, {
      ...lines[lineIndex],
      [settingType]: value
    })
  }

  const handleImageRefresh = () => {
    if (!isImageLoading) {
      void refreshCameraImage()
    }
  }

  return (
    <Grid>
      <Grid.Col span="content">
        <AnnotationArea
          enabledLogicParameters={enabledLogicParameters}
          imageUrl={cameraImage}
          isImageLoading={isImageLoading}
          width={FRAME_WIDTH}
          height={FRAME_HEIGHT}
          lines={lines}
          onLineAdd={handleLineAdd}
          onLineChange={onLineChange}
          onImageRefresh={() => void handleImageRefresh()}
        />
      </Grid.Col>

      <Grid.Col span="auto">
        <Box h="100%" miw={300} maw={FRAME_WIDTH}>
          {lines.length === 0 && (
            <Card h="100%" p="lg" radius={0} withBorder>
              <Center h="100%">
                <EmptyLogicText />
              </Center>
            </Card>
          )}

          {logicType === LibraryLogicType.ObjectDetection &&
            lines.map((line, i) => (
              <Box key={line.name} mb="md">
                <LogicLineDetection
                  type={line.type}
                  name={line.name}
                  color={line.color}
                  label={
                    <FormattedMessage id="logic.objectDetection.labelName" />
                  }
                  triggers={line.triggers}
                  notification={line.notification}
                  videoUpload={line.video_upload}
                  labelOptions={labelOptions}
                  triggerError={streamErrors[`lines.${i}.triggers`] as string}
                  onLineRemove={() => onLineRemove(i)}
                  onTriggerChange={(triggers) =>
                    handleTriggerChange(triggers, i)
                  }
                  onSettingChange={(settingType, value) =>
                    handleSettingChange(settingType, value, i)
                  }
                />
              </Box>
            ))}

          {logicType === LibraryLogicType.ObjectCount &&
            lines.map((line, i) => (
              <Box key={line.name} mb="md">
                <LogicLineCount
                  type={line.type}
                  name={line.name}
                  color={line.color}
                  triggers={line.triggers}
                  notification={line.notification}
                  videoUpload={line.video_upload}
                  labelOptions={labelOptions}
                  triggerError={streamErrors[`lines.${i}.triggers`] as string}
                  onLineRemove={() => onLineRemove(i)}
                  onTriggerChange={(triggers) =>
                    handleTriggerChange(triggers, i)
                  }
                  onSettingChange={(settingType, value) =>
                    handleSettingChange(settingType, value, i)
                  }
                />
              </Box>
            ))}
        </Box>
      </Grid.Col>
    </Grid>
  )
}
