import {
  ActionIcon,
  Badge,
  Box,
  Card,
  Image,
  Text,
  ThemeIcon,
  UnstyledButton,
  rem
} from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { IconCheck, IconLockFilled } from '@tabler/icons-react'
import { MouseEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import ImagePlaceholder from '@/assets/image-placeholder.png'
import { SelectionIndicator } from './SelectionIndicator'

const IMAGE_HEIGHT = 160
const SELECTION_PADDING = 12

type MediaCardProps = {
  fileName: string
  thumbnailUrl: string
  isSelectable?: boolean
  isSelectionMode?: boolean
  isSelected?: boolean
  isLabeled?: boolean
  isLocked?: boolean
  onClick?: () => void
  onSelectionChange?: () => void
}

export const MediaCard = ({
  fileName,
  thumbnailUrl,
  isSelectable,
  isSelectionMode,
  isSelected,
  isLabeled,
  isLocked,
  onClick,
  onSelectionChange
}: MediaCardProps) => {
  const { hovered, ref } = useHover()

  const cardStyles = {
    root: {
      transition: 'transform 0.3s ease',
      transform: hovered ? 'scale(1.02)' : 'scale(1)',

      borderColor: isSelected
        ? 'var(--mantine-color-brand-primary-6)'
        : 'var(--mantine-color-gray-6)',

      outline: isSelected
        ? '1px solid var(--mantine-color-brand-primary-6)'
        : 'none'
    }
  }

  const imageHeight = isSelected
    ? IMAGE_HEIGHT - SELECTION_PADDING * 2
    : IMAGE_HEIGHT

  const handleClick = () => {
    if (isSelectionMode) {
      onSelectionChange?.()
    } else {
      onClick?.()
    }
  }

  const handleStartSelection = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onSelectionChange?.()
  }

  return (
    <UnstyledButton component="a" onClick={handleClick}>
      <Card
        ref={ref}
        styles={cardStyles}
        shadow={hovered ? 'md' : 'xs'}
        radius="md"
        withBorder
      >
        <Card.Section p={isSelected ? SELECTION_PADDING : undefined}>
          <Image
            h={imageHeight}
            fit="cover"
            src={thumbnailUrl}
            fallbackSrc={ImagePlaceholder}
            styles={{
              root: {
                opacity: hovered ? 0.8 : 1
              }
            }}
          />

          {isSelectable && !isSelectionMode && !isLocked && hovered && (
            <Box pos="absolute" top={4} right={4}>
              <ActionIcon
                radius="xl"
                size="sm"
                color="brand-primary"
                styles={{
                  root: {
                    boxShadow: '0 0 5px 1px var(--mantine-color-white)'
                  }
                }}
                onClick={handleStartSelection}
              >
                <IconCheck style={{ width: '70%', height: '70%' }} />
              </ActionIcon>
            </Box>
          )}

          {isSelectable && isSelectionMode && !isLocked && (
            <Box pos="absolute" top={4} right={4}>
              <SelectionIndicator isSelected={isSelected || false} />
            </Box>
          )}

          {isLocked && (
            <Box pos="absolute" top={4} right={4}>
              <ThemeIcon variant="light" color="white" radius="xl" size="sm">
                <IconLockFilled style={{ width: '80%', height: '80%' }} />
              </ThemeIcon>
            </Box>
          )}

          {isLabeled && (
            <Box pos="absolute" top={4} left={4}>
              <Badge size="xs" color="green">
                <FormattedMessage id="annotation.labeled" />
              </Badge>
            </Box>
          )}
        </Card.Section>

        <Card.Section bg="gray.9" py={4} px={8}>
          <Text size={rem(11)} c="white" truncate>
            {fileName}
          </Text>
        </Card.Section>
      </Card>
    </UnstyledButton>
  )
}
