import { useMutation } from '@tanstack/react-query'
import { workspaceApi } from '@/api/workspaceApi'

export const workspaceQueryKeys = {}

export const useCreateWorkspace = () => {
  return useMutation({
    mutationFn: workspaceApi.createWorkspace
  })
}
