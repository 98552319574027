import { useGetAppMLModels } from '@/queries/modelQueries'

export const useModelListStatus = (applicationId: string) => {
  const { data, isFetching } = useGetAppMLModels(applicationId)

  return {
    primaryModelCount: data?.pages[0].count || 0,
    // primaryModelCount: 1,
    secondaryModelCount: 0, // TODO: remove test value after API integration
    isStatusLoading: isFetching
  }
}
