import { Alert, Group, ThemeIcon } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

type TrainingCompletedBannerProps = {
  rightSection?: ReactNode
}

export const TrainingCompletedBanner = ({
  rightSection
}: TrainingCompletedBannerProps) => {
  return (
    <Alert
      variant="light"
      color="green"
      title={<FormattedMessage id="training.status.completed" />}
      icon={
        <ThemeIcon color="green" size="sm" radius="xl">
          <IconCheck style={{ width: '70%', height: '70%' }} />
        </ThemeIcon>
      }
    >
      <Group justify="space-between" align="center">
        <FormattedMessage id="training.completedDescription" />

        {rightSection}
      </Group>
    </Alert>
  )
}
