import { Button, Group, Modal, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

type ConfirmDatasetCompletionProps = {
  opened: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmDatasetCompletion = ({
  opened,
  onClose,
  onConfirm
}: ConfirmDatasetCompletionProps) => {
  return (
    <Modal
      title={<FormattedMessage id="datasets.confirmCompletion.title" />}
      size="lg"
      opened={opened}
      onClose={onClose}
    >
      <Text size="sm" mb="xl">
        <FormattedMessage id="datasets.confirmCompletion.message" />
      </Text>

      <Group justify="end">
        <Button miw={120} variant="default" onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button onClick={onConfirm}>
          <FormattedMessage id="datasets.confirmCompletion.ok" />
        </Button>
      </Group>
    </Modal>
  )
}
